export const en = {
  message: {
    requiredField: 'Required field',
    noRecords: 'No record found!',
    confirmDel: 'Are you sure you wish to delete?',
    confirmApproval: 'Are you sure you wish to approve this occurrence?',
    noResultsFor: 'No results for'
  },
  login: {
    username: 'User',
    password: 'Password',
    login: 'Login',
    entrar: 'Log in',
    descricao: 'Enter your access data in the form below:'
  },
  exportarFicha: {
    author: 'author',
    biodiversity_impact: 'biodiversity_impact',
    chemical_control: 'chemical_control',
    class: 'class',
    common_name: 'common_name',
    control_biological: 'control_biological',
    data_bibliography: 'data_bibliography',
    economic_impact: 'economic_impact',
    economic_use: 'economic_use',
    family: 'family',
    health_impact: 'health_impact',
    intro_data: 'intro_data',
    intro_description: 'intro_description',
    invasion_preferential_environments: 'invasion_preferential_environments',
    kingdom: 'kingdom',
    location: 'location',
    morphophysiology_description: 'morphophysiology_description',
    native_distribution_area: 'native_distribution_area',
    natural_environment: 'natural_environment',
    oorder: 'oorder',
    origin: 'origin',
    phyllum: 'phyllum',
    physical_control: 'physical_control',
    prevention: 'prevention',
    scientific_name: 'scientific_name',
    social_impact: 'social_impact',
    species_id: 'species_id',
    voc_cause_introduction: 'voc_cause_introduction',
    voc_diet: 'voc_diet',
    voc_dispersal_routes: 'voc_dispersal_routes',
    voc_dispersion_vectors: 'voc_dispersion_vectors',
    voc_form: 'voc_form',
    voc_introduction_type: 'voc_introduction_type',
    voc_reproduction: 'voc_reproduction',
    voc_spread: 'voc_spread',
    world_invasive_place: 'world_invasive_places'
  },
  label: {
    voltar: 'Back',
    outro: 'Other',
    filtros: 'Filters',
    acesseBaseDados: 'Access the database',
    conhecaNossaRede: 'Latin American network',
    baseDadosNacional: 'National Database',
    politicaDeDados: 'Data policy',
    comiteCientifico: 'Scientific committee',
    colabore: 'Collaborate',
    especiesMarinhas: 'Marine species',
    peixesAquario: 'Aquarium fishes',
    peixesAquicultura: 'Aquaculture fishes',
    pescaDesportiva: 'Sport fishing',
    pets: 'Pets',
    plantasForrageiras: 'Forage plants',
    plantasOrnamentais: 'Ornamental plants',
    usoFlorestal: 'Forestry',
    tutorial: 'Tutorial',
    especies: 'Species',
    colaboradores: 'Collaborators',
    referencias: 'References',
    projetos: 'Projects',
    saibaMais: 'More info',
    contida: 'Contained',
    casual: 'Casual',
    estabelecida: 'Established',
    invasora: 'Invasive',
    exibirListagem: 'View List',
    concluir: 'Complete',
    fechar: 'Close',
    próximo: 'Next',
    anterior: 'Former',
    clickAqui: 'Click here',
    numRegistrosEncontrados: 'Number of records found:',
    exibir10Reg: 'Display 10 records',
    exibir25Reg: 'Display 25 records',
    exibir50Reg: 'Display 50 records',
    exibir100Reg: 'Display 100 records',
    limpar: 'Clear',
    buscar: 'Search',
    addColaborador: 'Add collaborator',
    nome: 'Name',
    sobrenome: 'Last name',
    aprovarColaborador: 'Approve collaborator',
    salvarColaborador: 'Save collaborator',
    titulo: 'Title',
    cargo: 'Position',
    especialidade: 'Expertise',
    instituicao: 'Institution',
    endereco: 'Address',
    estado: 'State',
    cidade: 'City',
    pais: 'Country',
    email: 'E-mail address',
    telefone: 'Telephone 1',
    telefone2: 'Telephone 2',
    websiteURL: 'Website URL',
    midiaSocial1: 'Social media 1',
    midiaSocial2: 'Social media 2',
    midiaSocial3: 'Social media 3',
    observacoesAdicionais: 'Additional observations',
    adicionar: 'Add',
    editando: 'Editing',
    acoes: 'Actions',
    usuarixs: 'Users',
    adicionarUsuario: 'Add user',
    descricao: 'Description',
    ativo: 'Active',
    sim: 'Yes',
    nao: 'No',
    salvarUsuarix: 'Save user',
    senha: 'Password',
    observacoes: 'Observations',
    grupos: 'Groups',
    basico: 'Basic',
    administrator: 'Administrator',
    taxonomia: 'Taxonomy',
    nomeCientifico: 'Latin name',
    adicionarEspecie: 'Add species',
    genero: 'Genus',
    familia: 'Family',
    ordem: 'Order',
    classe: 'Class',
    phylumDivisao: 'Phylum / Division',
    reino: 'Kingdom',
    phylum: 'Phylum',
    origem: 'Origin',
    descricaoInvalida: 'Invalid description',
    selecioneFamilia: 'Please select a Family.',
    selecioneOrdem: 'Please select an Order.',
    selecioneClasse: 'Please select a Class.',
    selecioneDivisao: 'Please select a Phylum.',
    selectioneReino: 'Please select a Kingdom.',
    selectioneGenero: 'Please select a Genus.',
    addReferencia: 'Add reference',
    autoresEX: 'Authors (ex. ZILLER, S. R.; DECHOUM, M. S.)',
    aprovarReferencia: 'Approve reference',
    salvarReferencia: 'Save reference',
    autorxsEX: 'Authors (ex. ZILLER, S. R.; DECHOUM, M. S.)',
    autores: 'Authors',
    local: 'Location (Ex. Salvador, BA  /  África do Sul: Cidade do Cabo)',
    localFiltro: 'Location',
    edicao: 'Edition (ex. 2 ed.)',
    editora: 'Editor',
    revista: 'Journal',
    anoPublicacao: 'Publication year',
    meioDivulgacao: 'Publication means',
    volume: 'Volume (ex. v. 3)',
    numero: 'Number (ex. n. 28)',
    pagina: 'Pages (ex. p. 25-34 / 245p.)',
    url: 'DOI / URL',
    palavraChave: 'Keywords',
    anoPublic: 'Publication year',
    addProjeto: 'Add project',
    aprovarProjeto: 'Approve project',
    salvarProjeto: 'Save project',
    contato: 'Contact',
    areaGeografica: 'Geographic range',
    objetivos: 'Objectives',
    breveDescricao: 'Brief description',
    dataInicio: 'Start date',
    dataTermino: 'End date',
    comentarios: 'Comments',
    anoInicio: 'Start year',
    anoTermino: 'End year',
    ocorrencias: 'Occurrences',
    exportarDados: 'Export data',
    addOcorrencia: 'Add occurrence',
    visualizarOcorrencia: 'View occurrence',
    alterarOcorrencia: 'Change occurrence',
    ocorrenciasVinculadas: 'Linked occurrences',
    areaProtegida: 'Protected area',
    especie: 'Species',
    aprovarOcorrencia: 'Approve occurrence',
    salvarOcorrencia: 'Save occurrence',
    colaborador: 'Collaborator',
    municipio: 'Municipality',
    municipioSede: 'Municipality (city)',
    municipioCentroide: 'Municipality (centroid)',
    referenciaLocal: 'Local reference',
    referencia: 'Reference',
    latitude: 'Latitude',
    longitude: 'Longitude',
    coordenadasCorrespondem: 'The coordinates correspond to',
    distanciaAproximada: 'Approximate distance',
    ambiente: 'Environment',
    baciaHidro: 'Basin',
    descricaoInvasao: 'Description of the invasion',
    abundancia: 'Abundance',
    situacaoPopulacional: 'Population status',
    manejo: 'Management',
    dataEntrada: 'Entry date',
    dataObservacao: 'Observation date (ex. 2020 ou 13/05/2020)',
    validado: 'Validated',
    especieMuseuHerbario: 'Museum or herbarium specimen',
    selecioneTipo: 'Select the type',
    herbario: 'Herbarium',
    museu: 'Museum',
    numeroExemplaInstituicao: 'Specimen number (Institution)',
    numeroExemplarColetor: 'Specimen number (Collector)',
    selecioneOpcao: 'Select an option',
    pointList1: 'A point of occurrence of one or more individuals of the species',
    pointList2: 'The center of a group of individuals of the species',
    pointList3: 'A point of occurrence at an approximate distance of:',
    coordinatesSourceList1: 'A - A political or administrative unit',
    coordinatesSourceList2: 'B - The actual site of occurrence',
    ultimoRegistroBaseDados: 'Latest records in database',
    ultimosAcessos: 'Latest access',
    fichaTecnicaEspecie: 'Species factsheet',
    fichaTecnicaEspecieMMA: 'Species factsheet MMA version 1',
    fichaTecnicaEspecieMMA2: 'Species factsheet MMA version 2',
    invasoras: 'Invasive',
    contidasBrasil: 'Contained',
    ausentesBrasil: 'Absent',
    deficiencia: 'Data deficient (DD)',
    formatosDisponiveisExportacao: 'Available formats for export:',
    biologiaEcologia: 'Biology and ecology',
    usoEconomico: 'Economic use',
    usoEconomicoDescricao: 'Economic use - description',
    invasaoBiologica: 'Biological invasion',
    impactos: 'Impacts',
    habitat: 'Habitat',
    nomeCientificoSinonimos: 'Scientific name + synonyms',
    nomesPopulares: 'Common names',
    reproducao: 'Reproduction',
    dispersao: 'Dispersal',
    dieta: 'Diet',
    formaBiologica: 'Habit',
    ambientesPreferenciaisInvasao: 'Habitats more susceptible to invasion',
    tipoIntroducao: 'Introduction type',
    causaIntroducao: 'Cause of introduction',
    localIntroducao: 'Place of introduction',
    anoIntroducao: 'Year of introduction',
    marinho: 'Marine',
    dulcicola: 'Freshwater',
    terrestre: 'Terrestrial',
    validarEspecie: 'Validate species',
    salvarEspecie: 'Save species',
    data: 'Date',
    confiabilidade: 'Confidence level',
    resultadosAnalisesRisco: 'Risk assessment results',
    risco: 'Risk',
    areaOrigem: 'Area of origin',
    areaDistribuicaoNatural: 'Native range',
    ambientesNaturais: 'Natural habitats',
    descricaoEspecie: 'Species description',
    ambientesPreferenciaisInvasaoDescricao: 'Habitats more susceptible to invasion - description',
    outrosLocaisOndeEspecieInvasora: 'Other places where the species is invasive',
    impactosEcologicos: 'Ecological impacts',
    impactosEconomicos: 'Economic impacts',
    impactosSociais: 'Social impacts',
    impactosSaude: 'Health impacts',
    categoriaEICAT: 'EICAT category',
    mecanismosImpactoEICAT: 'EICAT impact mechanisms',
    nivelConfiancaAvaliacaoEICAT: 'EICAT assessment confidence level',
    referenciaEICAT: 'EICAT reference',
    dataEICAT: 'EICAT date (e.g. 2021)',
    categoriaSEICAT: 'SEICAT category',
    mecanismosImpactoSEICAT: 'SEICAT impact mechanisms',
    nivelConfiancaAvaliacaoSEICAT: 'SEICAT assessment confidence level',
    referenciaSEICAT: 'SEICAT reference',
    dataSEICAT: 'SEICAT date (e.g. 2021)',
    digitarSomenteAno: 'Enter year only - e.g. 2021',
    viasCDBCategorias: 'CDB pathways - categories',
    viasIntroducao: 'Pathways of introducion and spread',
    viasVetoresCDBSubcategorias: 'CDB pathways - subcategories',
    vetoresIntroducao: 'Vectors of introducion and spread',
    introducao: 'Introduction',
    adicionarIntroducao: 'Add introduction',
    ano: 'Year',
    descricaoIntroducao: 'Introduction description',
    salvar: 'Save',
    cancelar: 'Cancel',
    excluir: 'Delete',
    medidasPreventivas: 'Prevention measures',
    medidasPreventivasMMA: 'Prevention measures MMA',
    medidasDeteccaoPrecoce: 'Early detection measures',
    controleMecanico: 'Mechanical control',
    controleQuimico: 'Chemical control',
    controleBiologico: 'Biological control',
    distribuicaoPais: 'Distribution in the country',
    vejaMapaOcorrencias: 'See occurrence map',
    ecologiaUso: 'Ecology and use',
    introducaoDispersao: 'Introduction and spread',
    analiseRisco: 'Risk analysis',
    nomeComum: 'Common name',
    foto: 'Photograph',
    credito: 'Credit',
    sinonimos: 'Synonyms',
    autor: 'Author',
    subespecie: 'Subspecies',
    autorSubespecie: 'Author of subspecies',
    pendenteAnalise: 'Analysis pending',
    cancelarVoltar: 'Cancel / Back',
    versaoAnterior: 'Former version',
    versaoAtual: 'Current version',
    colaboradorxs: 'Collaborators',
    consultaTaxonomiaListaCompletaEspecies: 'Taxonomy - Complete list of species',
    consultaEspeciesOrigemAmbientes: 'Species, origin, habitats susceptible to invasion, impacts',
    consultaEspeciesManejo: 'Species and management',
    consultaParaGerarFichaTecnica: 'Species factsheet without places of occurrence',
    consultaEspeciesOcorrencias: 'Species and places of occurrence',
    exportarDadosConsultas: 'Export data',
    ocorrenciasApp: 'Occurrences app',
    dataCadastro: 'Registration date',
    criadoPor: 'Created by',
    nivelCerteza: 'Confidence level',
    byApp: 'From mobile app',
    verDados: 'View data',
    appuser: 'App user',
    profissao: 'Profession',
    colobadorJaCadastrado: 'Collaborator already registered',
    especialidadeApp: 'Expertise app',
    cep: 'Zip code',
    categoriaCDB: 'CBD Category',
    subCategoriaCDB: 'CDB Subcategory',
    mensagemEnviada: 'Message sent successfully.'
  },
  home: {
    destaque1: 'National Invasive Alien Species Database',
    destaqueDescricao1: 'Welcome! This database is built in collaboration with many people involved in nature conservation efforts in Costa Rica. Please visit!',
    destaque2: 'Since 2005 documenting invasive alien species in Costa Rica',
    destaqueDescricao2: 'This database was developed and updated with funds from international projects. A Scientific Committee supports data management.',
    destaque3: 'How does it work?',
    destaqueDescricao3: 'This is an open access database, but please cite it as a reference in your work! Professionals may request a login to contribute data.',
    destaque4: 'Our purpose',
    destaqueDescricao4: 'Provide data on invasive alien species in Costa Rica for governmental management, control programs and activities, scientific research, and the general public.',
    titulo: 'Find species, filter and export data',
    conteudo: 'Here you will find information about the native area of distribution of the species, biological and ecological characteristics, invaded habitats, places where the species is present and where it invades in the world and in Costa Rica, including a map of occurrences in the country, history of introduction and use, vectors and routes of introduction and dispersal, information about its management and references. Use the filters to find the information you want and export it in different formats.',
    conteudoEspecies: 'Species lists, ecological traits, native range, where the species are invasive in the world, vectors and pathways, risk assessment, uses, management options, places of occurrence in Costa Rica, references.',
    conteudoColaboradores: 'Registry of people who have contributed data to the database. If you need to find an expert or know more about a place of occurrence, look for contacts here.',
    conteudoReferencias: 'Complete list of references used as a basis for the data fields on invasive alien species.',
    conteudoProjetos: 'Invasive alien species research and management projects in Costa Rica. If you are working on a project, please let us know so we can register it.',
    titulo1: 'Conozca las especies exóticas invasoras.',
    conteudo1: 'Listas de especies exóticas invasoras organizadas según el motivo o vía de introducción a Costa Rica, usos o efectos sobre los ecosistemas. Las páginas incluyen una explicación sobre cada grupo de especies y vínculos para fuentes de información adicionales.'
  },
  areaParceiros: {
    titulo: 'Latin America and Caribbean Invasive Alien Species Database Network:',
    argentina: 'Argentina',
    paraguai: 'Paraguay',
    uruguai: 'Uruguay'
  },
  redeBaseDeDados: {
    cabecalhoTitulo: 'Latin-American and Caribbean Network',
    paragrafo1: 'This Database Network originates in the year 2005 as I3N (IABIN Invasives Information Network), a thematic network on invasive alien species then developed as part of the InterAmerican Biodiversity Information Network established by governments of countries in the Americas in 2001.',
    paragrafo2: 'Each country had a Lead for the network in charge of developing a national database and sharing information on invasive species. The people in charge of the national databases are:',
    paragrafo2_1: '',
    marcador1: 'Argentina: Dr. Sergio M. Zalba, Professor, Universidad Nacional del Sur, Bahía Blanca (since 2003);',
    marcador2: 'Brazil: Dr. Silvia R. Ziller, Founder and Executive Director of the Horus Institute for Environmental Conservation and Development (since 2004);',
    marcador3: 'Chile: Dr. Aníbal Pauchard, Professor, Facultad de Ciencias Forestales, Universidad de Concepción;',
    marcador4: 'Costa Rica: PhD. Eduardo Chacón-Madrigal, Professor at the University of Costa Rica. Researcher at the Biodiversity and Tropical Ecology Research Center. Co-coordinator of Flora Invasive and Naturalized of Central America.',
    marcador5: 'Ecuador: Dr. Diego Inclán, Executive Director of the National Institute of Biodiversity (since 2021) and Francisco José Prieto Albuja;',
    marcador5_1: 'Honduras: Dra Lilian Ferrufino, Universidad Nacional Autónoma de Honduras;',
    marcador5_2: 'Jamaica: Dr. Suzanne Davis, Clearing-House Mechanism, Institute of Jamaica (since 2004).',
    marcador5_3: 'Paraguay: Hugo Fernando del Castillo, Guyra Paraguay Bird Conservation (since 2006);',
    marcador5_4: 'Uruguay: Dr. Ernesto Brugnoli, Professor, Universidad de la República, Montevideo (since 2005), and M.Sc. Marcelo Iturburu, Coordinator of the Invasive Alien Species Committee of the Ministry of Environment (since 2018).',
    paragrafo3: 'IABIN received funding from a World Bank (GEF) project between 2004 and 2011, when it was terminated. Despite the closing of IABIN, some of the network Leads continued to develop and update the national databases (Brazil, Argentina, Uruguay, Paraguay and Jamaica).',
    paragrafo4: 'In 2021, the four South American countries received funding from the Bio-Bridge Initiative of the Convention on Biological Diversity to revitalize the Argentina, Brazil, Paraguay, and Uruguay databases. The interface was redesigned, new functions were added, and the programming was totally redone.',
    paragrafo5: 'The database developers are:',
    marcador6: 'The Programmer João Scucato, from Curitiba, Brazil, who developed the first version in Microsoft Access, 2004-2005;',
    marcador7: 'The Programmer Alejandro Moreno, from Argentina, who has provided support to the databases since 2006, and rescued data from Paraguay and Uruguay for this new version. He also developed the',
    marcador7_1: 'Common Platform',
    marcador7_2: 'for the South American countries in 2021;',
    marcador8: 'The Web Designer Rafael Moura and Programmer Thiago Lôbo, through',
    marcador8_1: 'Plataforma Vibbra',
    marcador8_2: 'in Florianópolis, Brazil; ',
    marcador9: 'The programmer Leonardo Rotondano, in charge of developing the cell phone application for invasive species occurrences.',
    paragrafo6: 'We hope to expand the network once again to include more countries in Latin America and the Caribbean, making more data on invasive alien species available for management and policy, public knowledge and on-site field work.',
    paragrafo7: 'Latin America and Caribbean Invasive Alien Species Database Network:',
    paragrafo8: '',
    paragrafo9: ''
  },
  baseDadosNacional: {
    cabecalhoTitulo: 'Invasive Alien Species Database',
    titulo: 'The Costa Rica Invasive Alien Species Database',
    paragrafo1: 'This database began to be developed in 2006 with the publication of a list of invasive alien plants introduced in Costa Rica',
    paragrafo1_1: ', compiled by Eduardo Chacón Madrigal e Guido Saborío Rodríguez, then members of the Association for the Conservation and Study of biodiversity (ACEBIO). This species list was the first set of data in the national invasive alien species database of Costa Rica, which was part of an invasive species information network then known as I3N (IABIN Invasives Information Network). The network was then coordinated by Andrea Grosse, a Biologist who dedicated much time and effort to build the network, supported the development of the database structure and of several other products such as risk analysis protocols, manuals and training courses on invasive species. The other person who was very important for the growth of the network was Dr. Annie Simpson of the USGS, then in charge of the Global Invasive Species Information Network (GISIN), created to establish standards for invasive alien species data in order to facilitate the integration of databases at the global level and in several countries. The funds for the development of the national database were provided by the World Bank through the GEF for a project coordinated by the USGS that aimed to establish the InterAmerican Biodiversity Information Network (IABIN).',
    paragrafo2: 'The I3N Network was active between 2001 and 2011, when the project ended. During that time, the database structure was distributed to 21 countries in the Americas along with training courses on invasive species management and database usage: Argentina, Brazil, Uruguay, Chile, Paraguay, Bolivia, Peru, Ecuador, Colombia, Venezuela, Surinam, The Dominican Republic, the Bahamas, Jamaica, St. Lucia, Panama, Costa Rica, Honduras, El Salvador, Nicaragua, and Guatemala.',
    paragrafo3: 'Unfortunately, the majority of the databases has been lost over time. The ones in operation since the start belong to Argentina (Universidad Nacional del Sur, managed by Dr. Sergio Zalba); Brazil (Instituto Hórus, managed by Dr. Sílvia Ziller and Dr. Michele de Sá Dechoum), Uruguay (Universidad de la República em Montevidéu, initially managed by Dr. Ernesto Brugnoli and more recently in a joint effort with the Ministry of Environment) and Jamaica (Institute of Jamaica, managed by Dr. Suzanne Davis). Cooperation between these database managers has never ceased to exist.',
    paragrafo4: 'In the year 2011, the database was updated from the original format in Microsoft Access to open software, in MySQL. On the occasion, many corrections and improvements were implemented thanks to feedback from users in several countries. The new version was then distributed to all countries in the network.',
    paragrafo5: 'Ten years later, in 2021, the BioBridge Initiative of the Convention on Biological Diversity in Montreal, Canada, provided funds for another update in a project prepared by Argentina, Brazil, Uruguay and Paraguay. In 2023, again with funds from the BioBridge Initiative, four more countries were included in the network: Chile, Jamaica, Honduras, and Costa Rica. The Costa Rica database was adopted by Eduardo Chacón Madrigal, associated researcher at the Tropical Ecology and Biodiversity Research Center at the Costa Rica University. Many improvements were implemented in order to facilitate data management, data collection and export by users. We hope that this version is more accessible and more user-friendly, and that the National Databases provide information for management at all levels, scientific research, and technical support to managers in the field. We also hope that it helps people avert the use of destructive species and promotes the use of indigenous species, especially to safeguard native biodiversity and the resilience of natural ecosystems in the Americas.',
    paragrafo6: 'We are very grateful to all who have collaborated with the database in some way, by providing or validating information, by appending data or any other of the many forms of support we have received over time. The richness of data available is the result of a large, collective effort.',
    paragrafo7: ''
  },
  politicaDeDados: {
    cabecalhoTitulo: 'Data policy',
    titulo: 'Concepts and sources of reference',
    criterio: 'Criteria for adding new species',
    avaliacao: 'Data asssessment and review',
    validacao: 'Data validation',
    paragrafo1: 'The concepts used in this database are aligned with the concepts used in the International Convention on Biological Diversity:',
    marcador1: 'native species – species found within its native range, where it evolved and is part of a biological community;',
    marcador2: 'alien (non-native) species – species outside its known native range (past or present);',
    marcador3: 'invasive alien species – species that threaten habitats, ecosystems or other species, generally causing impacts and changes in natural environments.',
    paragrafo2: 'The Brazil Invasive Alien Species Database lists species that are present in Brazil and have a history of invasion in the country. Records of occurrence in Brazil are provided for all species. There may be exceptions in the case of species of imminent risk of introduction to the country, to support early detection and rapid response. Other lists may be developed for species that have been introduced, have a history of invasion elsewhere, but have not been observed in the wild in the country. ',
    paragrafo3: 'The species contained in the database must not be considered equally invasive all over the country. They tend to invade certain habitats with higher compatibility or aggressiveness. For this reason, the habitat types each species invades are indicated, as well as places where they occur in the country. Species reported as invasive without geographic reference are not included until at least one or a few locations are confirmed.',
    paragrafo4: 'The Brazilian Classification System for terrestrial habitats used in the database was developed by the Sistema Holdridge Life Zone (Holdridge 1967). The terms used for marine and freshwater environments were defined with experts in the respective fields. The controlled vocabulay was adopted from the Global Invasive Species Database.',
    paragrafo5_1: 'The main taxonomic references used are the Missouri Botanical Gardens ',
    paragrafo5_2: 'The Plant List',
    paragrafo5_3: ', and the United States Integrated Taxonomic Information System - ITIS ',
    paragrafo5_4: 'Other more specific databases are commonly used as well to verify and complement data, such as the World Register of Marine Species',
    paragrafo5_5: 'as well as the Global Invasive Species Database ',
    paragrafo5_6: 'and the CABI Invasise Species Compendium',
    paragrafo6: 'Species are classified in the following categories for every occurrence record:',
    marcador4: '– when a species is restrained to human use, such as lab experiments, or under cultivation or breeding, not being present in natural areas.',
    marcador5: '– when a species is found in a natural area, but has been planted, or was recently introduced, and no evidence of invasion or spread is available. ',
    marcador6: '– when a species is found in natural areas and a population has formed locally, and there is no evidence of dispersal to other areas.',
    marcador7: '– when the species occurs in natural areas, has started to reproduce and spread beyond the point of introduction.',
    paragrafo7: 'A species may therefore be considered invasive in one location, while it is casually present in another, or simply contained in a third place.',
    paragrafo8: 'Data have been collected for the country since 2003 with suppport from hundreds of people, listed in the database as Collaborators. These data providers send articles, reports, or field data with occurrences of species that are then added to the database.',
    paragrafo8_en: 'All the information on species occurrences are always connected to the source, as the name of the data provider is included with each record as well as references of published materials. Other data are referenced in the descriptive fields. In case of doubt or need for more information on a point of occurrence, users can contact the person who sent or published the data. The link between data and source is meant to ensure that every contribution is properly acknowledged and cited when used by others.',
    paragrafo9_1: 'If you have data on invasive alien species to contribute to this database, please use the spreadsheet available from our website and send it to ',
    paragrafo9_2: ' or contact us.',
    paragrafo10: 'For other issues or questions, please contact us as well! ',
    paragrafo11: 'The criteria for adding invasive alien species to the database are: (a) the species is present in Brazil and at least one or a few reliable records of occurrence are available; (b) the species has a history of invasion in Brazil or elsewhere, preferably in climatic conditions that would favor establishment in the country. There may be exceptions to species that have not been found invading in Brazil, but are considered of high risk to biodiversity, have been introduced and are so far contained (under cultivation or breeding, in captivity, in aquaria, laboratories, etc.), or to species present in neighboring countries with which Brazil maintains relevant commercial relations, which might facilitate introductions. No invasive species is added without one or more validated records of occurrence.',
    paragrafo12: 'The database covers invasive alien species in all biological groups with potential of impact on biological diversity and/or on natural areas. While the same species may generate negative impacts on the economy, on social and cultural values, or on human or animal health, the focus of this database is on species that impact natural ecosystems and biological diversity.',
    paragrafo13: 'Once a species is included in the database, complementary information is appended. New data is included as available from published materials, and new occurrences are added from collaborators, field observations by practitioners and protected area managers or rangers, and technical and scientific publications. The addition of geographic coordinates is compulsory for all occurrences.',
    paragrafo14: 'The data is continually reviewed and upadted from publications or communication with collaborators. When species in the database are questioned as new data are available, for example on the native range including Brazil, expert advice is sought by the Scientific Committee in order to decide whether the species in question should be removed or not. A few species that were included from the first national survey conducted in Brazil were later excluded for lack of evidence on invasion or because data were insufficient to comply with the inclusion criteria established. This may happen, for example, when a non-native species is found in a natural area, but there is no evidence of invasion over time, or data are insufficient to confirm invasion potential in the country or in other places in the world; or still, when a species native range is uncertain, and DNA analyses show that it is a native species in the region. In all these cases, the data is stored in a standby list to avoid any losses, and the species is no longer shown in the database.',
    paragrafo15: 'The validation of data included in the database by data providers / collaborators is conducted by verifying the reliability of the species identification and of places of occurrence.',
    paragrafo16: 'Taxonomic validation',
    marcador8: 'Can the data provider (author of the publication or collector of the specimen) be considered an expert on the biological group to which the species belongs, or have equivalent experience?',
    marcador9: 'Is the occurrence documented in a photograph through which it is possible to identify of the organism with high certainty?',
    marcador10: 'Is the occurrence documented in an indexed and peer-reviewed publication or in a technical document prepared or reviewed by experts?',
    marcador11: 'Does the information correspond to a specimen deposited in a herbarium or museum?',
    marcador12: ' Does the occurrence correspond to a species that was already registered for the country?',
    marcador13: 'Is the taxonomy to which the group belongs stable and well-known?',
    marcador14: 'Do the characteristics of the species allow for a relatively simple identification?',
    paragrafo17: 'A positive answer to at least one of questions 1, 2, 3 or 4 leads directly to the recommendation to consider the data as validated. In case of answering negatively to these four questions, the validation will be obtained only with positive answers to the last three (5, 6 and 7).',
    paragrafo18: 'Geographic validation',
    marcador15: 'Does the data include geographic coordinates? Coordinates taken directly at the observation site as well as coordinates that correspond to a nearby geographic reference are considered (as indicated).',
    marcador16: 'Is there a description of the site that facilitates its location in the field?',
    marcador17: 'Is the occurrence documented by a photograph that allows for a reliable identification of the site?',
    marcador18: 'Do the geographic coordinates provided coincide with the location of the described and / or photographed site?',
    marcador19: 'The species is already cited for the country and, in that case, is the locality included within its known range of distribution or in a site that is compatible with the sites of known presence and with its dispersal capacity?',
    marcador20: 'Are the ecological conditions of the site, inferred from the location indicated, compatible with the general niche requirements of the species?',
    paragrafo19: 'The geographical location data are validated if:',
    marcador21: 'the answer to questions 1, 2 and/or 3, and 4 is positive;  ',
    marcador22: 'a positive or negative answer is assigned to question 1, a negative answer is given to questions 2 and 3, but questions 5 and 6 have positive answers. ',
    paragrafo20: 'The location will be considered not validated if: ',
    marcador23: 'the answer is positive to question 1 and questions 2 and/or 3, but negative to question 4; ',
    marcador24: 'negative or positive answer to question 1 and positive to one or none of questions 5 and 6.',
    paragrafo21: 'Finally, the data are considered validated if the validation analysis of the two criteria (taxonomy and location) is passed.'
  },
  comiteCientifico: {
    cabecalhoTitulo: 'Scientific committee',
    paragrafo1: 'The National Invasive Alien Species Database is supported by a network of collaborators who help validate information, share new publications and data. The network includes experts in environmental sciences and taxonomy, field practitioners and managers whose knowledge is invaluable for the maintenance of the database.',
    paragrafo2: 'In 2023, people from different agencies were invited to form a Scientific Committee for the National Invasive Alien Species Database. The role of the Scientific Committee is to provide support for data validation, contribute with data for the database, and help to build and maintain a network of collaborators for continuous data updates and increased data contributions.',
    paragrafo3: 'The following persons form the first Scientific Committee for the National Database:',
    marcador1: 'Eduardo Chacón Madrigal, Biologist, PhD, Biodiversity and Tropical Ecosystems Research Center, Costa Rica University. Database Manager;',
    marcador2: 'Angie Sánchez Núñez, Biologist, MSc in Wildlife, National Protected Area System, Ministry of Environment and Energy. Database collaborator and supporter;',
    marcador3: 'Shirley Ramírez Carvajal, Biologist, MSc, National Commission for Biodiversity Management. Ministry of Environment and Energy. Database collaborator and supporter;',
    marcador4: 'Tania Bermúdez Rojas, Biologist, MSc, Biological Sciences Faculty, Costa Rica National University. Database collaborator and supporter;',
    marcador4b: 'Jimmy Barrantes Madrigal, Biologist, MSc, CR Wildlife Foundation / ICOMVIS Costa Rica National University. Database collaborator and supporter;',
    marcador4c: 'Randall Arguedas Porras, Veterinarian, Dr. National Animal Health Service, Ministry of Agriculture and Grazing. Database collaborator and supporter;',
    marcador4d: 'Brayan Morera Chacón, Biólogo, MSc, Consejo Superior de Investigación Científica, España; Colaborador y asesor de base de datos;',
    marcador4e: 'Arturo Angulo Sibaja, Biólogo, PhD, Centro de Investigación en Biodiversidad y Ecosistemas Terrestres, Universidad de Costa Rica. Colaborador y asesor de base de datos.',
    paragrafo4: 'The role of the Scientific Committee:',
    marcador5: 'To make decisions on the inclusion of new species in the database;',
    marcador6: 'To make decisions on the exclusion of species for lack of data consistency or evidence of invasion;',
    marcador7: 'Support to data validation;',
    marcador8: 'Support on data reviews and for data searches;',
    marcador9: 'Support to locate and consult with experts for data validation and to improve database content;',
    marcador10: 'Recommendations for improvement of the database;',
    marcador11: 'Continuous increase and improvement of database content.'
  },
  especiesInstitucional: {
    titulo: 'Species search',
    paragrafo1: 'This section of the database contains information on invasive alien species. Data have been collected since 2004 in Brazil, when the Brazil Ministry of Environment conducted the first national survey on invasive alien species. Information is received from data providers, including experts on biological groups who also help validate data, and collated from scientific and technical publications.',
    paragrafo2_1: 'If you have occurrence data for invasive alien species in Costa Rica, ',
    paragrafo2_2: 'please contribute to update this database',
    verTutorial: 'Open tutorial',
    paragrafo3: 'Please use the following format to cite data from this system:',
    paragrafo4_1: 'Costa Rica National Invasive Alien Species Database. Universidad de Costa Rica. Checked on DD/MM/AAA.',
    paragrafo4_2: ' Accessed on __/__/____ (date)',
    tutorialTitulo1: 'Please cite our database',
    tutorialDescricao1: 'Please use this citation format in publications.',
    tutorialTitulo2: 'Use the filters',
    tutorialDescricao2: 'You’ll find some options to filter contents here.',
    tutorialTitulo3: 'Use the filters: select data',
    tutorialDescricao3: 'Choose filters and combine data by place, biological group, use, taxonomy, etc.',
    tutorialTitulo4: 'Number of records',
    tutorialDescricao4: 'This is the number of invasive alien species in the database or resulting from your search using combined filters.',
    tutorialDescricao5: 'Here you can select the number of records you wish to view per page.',
    tutorialTitulo6: 'List of invasive alien species',
    tutorialDescricao6: 'List of all species or search results using filters. Click on the name of the species to see the specific data set.',
    tutorialTitulo6_1: 'Export data',
    tutorialDescricao6_1: 'Here you can filter data and choose the format to export.',
    tutorialDescricao7: 'Taxonomic classification of the species selected, common names, synonyms, and a picture when possible!',
    tutorialTitulo8: 'Ecology and use',
    tutorialDescricao8: 'Description, biology and ecology of the species selected, native range, type of habitats it invades, and economic uses.',
    tutorialTitulo9: 'Introduction and spread',
    tutorialDescricao9: 'How the species arrived, why it was introduced, and how it spreads to other areas.',
    tutorialTitulo10: 'Impacts',
    tutorialDescricao10: 'Environmental, economic, health, and cultural impacts. Classification of environmental impacts according to the IUCN EICAT protocol.',
    tutorialTitulo11: 'Management',
    tutorialDescricao11: 'Prevention and early detection measures, mechanical, chemical, biological control.',
    tutorialTitulo12: 'Risk analysis',
    tutorialDescricao12: 'Risk analysis results for the species selected.',
    tutorialTitulo13: 'Places of occurrence',
    tutorialDescricao13: 'Location, ecosystems, protected areas, states in the country where the species occurs, and stage of invasion – see the map!',
    tutorialDescricao14: 'List of references used to compile data on the species and other publications on the species.',
    tutorialTitulo15: 'Projects',
    tutorialDescricao15: 'List of research and management projects on the species selected.'
  },
  colaboradoresInstitucional: {
    titulo: 'Collaborators',
    paragrafo1: 'Collaborators are those who contribute data to this information system, as well as those who collect museum or herbarium specimens, autor publications used as data sources or provide support for species identification or to validate information on invasive alien species. ',
    paragrafo2: 'Contributions of places of occurrence for species in the database are highly relevant for us to update distribution data and detect new areas of invasion. This in turn helps managers respond with management actions and allows us to share knowledge on species locations.',
    paragrafo3: 'Please use the following format to cite data from this system:',
    paragrafo4_1: 'Costa Rica National Invasive Alien Species Database. Universidad de Costa Rica. Checked on DD/MM/AAA.',
    paragrafo4_2: ' Accessed on __/__/____ (date)'
  },
  especiesMarinhasInstitucional: {
    titulo: 'Marine and coastal ecosystems',
    paragrafo1: 'The largest volume of commercial goods is currently moved around by ship between remote areas on the planet. Ships can carry small organisms that establish in the ports of arrival. Most invasive species in marine and coastal ecosystems is introduced non-intentionally, very likely in ballast water in ships that fill up tanks in one port then discharge it close to the port of arrival. Mollusks, crustaceans, and other organisms along the coast were probably introduced this way.',
    paragrafo2: 'Other invasive species, such as the lionfish, arrived in coastal ecosystems after being non-intentionally released from a commercial aquarium. The trade of ornamental fishes imports hundreds of species with establishment potential if populations are not properly managed.',
    paragrafo3: '',
    paragrafo4: 'Pterois volitans - pez león',
    paragrafo4b: 'Photo: Dinkum',
    paragrafo5: 'Anadara transversa - piangua transvera',
    paragrafo5b: 'Photo: Andrew C',
    paragrafo6: 'Panaeus monodon - camaron tigre gigante',
    paragrafo7: 'Photo: CSIRO Science Image 2992'
  },
  ecosistemasDeAguaDoceInstitucional: {
    titulo: 'Freshwater ecosystems',
    paragrafo1: 'Freshwater ecosystems are essential for human life and the economy. They include rivers, streams, lakes, lagoons, and wetlands. These ecosystems provide waterways for navigation, electricity, irrigation, water for human consumption, recreation, landscaping, and habitat for wildlife. Invasion by non-native species is one of the greatest threats to these ecosystems. Aquatic plants, fishes, and invertebrates are the main groups invading freshwater ecosystems. The main pathways of introduction of freshwater species are aquaculture, sport fishing, and the ornamental fish trade.',
    paragrafo2: '',
    paragrafo3: 'Invasive alien species may lead native species to extinction and reduce electricity production and the availability of water for human use. The common waterweed Egeria densa, used as an ornamental plant in aquaria, was detected as an invasive plant in some dams built for electricity production in Costa Rica.',
    paragrafo4: '',
    marcador1: '',
    marcador2: '',
    marcador3: '',
    paragrafo5: 'Macrobrachium rosenbergii - camaron azul malayo',
    paragrafo5b: 'Photo: KKPCW',
    paragrafo6: 'Melanoides tuberculata - caracol trompeta malayo',
    paragrafo6b: 'Photo: Dennis L',
    paragrafo7: 'Oreochromis mossambicus - tilapia',
    paragrafo7b: 'Photo: Greg Hume',
    paragrafo8: 'Pontederia crassipes - choreja',
    paragrafo8b: 'Photo: Eduardo Chacón-Madrigal',
    paragrafo9: 'Procambaarus clarkii - camarón de rio americano',
    paragrafo9b: 'Photo: Alvesgaspar'
  },
  ecosistemasTerrestresInstitucional: {
    titulo: 'Terrestrial environments',
    paragrafo1: 'Humans inhabit and carry out multiple activities in terrestrial environments. Activities such as agriculture, grazing, and urbanization favor the arrival of non-native species. Some of these species are invasive, and can affect crops and animal production, causing economic losses. Introduced species, intentionally or not, can invade natural environments, such as forests, or delay natural succession, generating negative impacts.',
    paragrafo2: 'For example, the rose apple (Syzygium jambos) is a tree introduced for fruit production that invaded abandoned coffee plantations and surrounding forests over time. The jaragua grass (Hyparrhenia rufa) was introduced for cattle ranching and affects dry tropical forests because it increases the frequency of fire events.',
    paragrafo3: '',
    paragrafo3b: '',
    paragrafo4: 'Hemidactylus frenatus - gecko asiático',
    paragrafo4b: 'Photo: Hopeland',
    paragrafo5: 'Musa velutina - banano rosa',
    paragrafo5b: 'Photo: Eduardo Chacón-Madrigal',
    paragrafo6: 'Nylanderia fulva - hormiga loca',
    paragrafo6b: 'Photo: Bentleypkt',
    paragrafo7: 'Syzygium jambos - manzana rosa',
    paragrafo7b: 'Photo: Eduardo Chacón-Madrigal',
    paragrafo8: 'Ulex europaeus - chucero',
    paragrafo8b: 'Photo: Eduardo Chacon-Madrigal'
  },
  ecosistemasUrbanosInstitucional: {
    titulo: 'Urban ecosystems',
    paragrafo1: 'Urban environments are areas where natural ecosystems were mostly eliminated, generating invasion opportunities for non-native species. In cities and towns, non-native species arrive due to the pet trade, transport of goods, and the ornamental plant trade. Some plants and animals manage to escape cultivation or breeding grounds and invade more easily due to the high level of disturbance and lack of competition with native species.',
    paragrafo2: 'Besides, areas occupied by people may provide favorable conditions for invasion because natural predators have been eliminated, and because of the availability of water and food. Examples of invasive alien species in urban areas are the European pigeon (Columba livia), the black rat (Rattus rattus), the Norwegian rat (Rattus norvegicus), the mouse (Mus musculus), and many species of plants.',
    paragrafo3: '',
    paragrafo4: 'Columbia livia - paloma europea',
    paragrafo4b: 'Photo: Eduardo Chacon-Madrigal',
    paragrafo5: 'Felis silvestris - Gato doméstico',
    paragrafo5b: 'Photo: Sakthibalan',
    paragrafo6: 'Rattus rattus - rata negra',
    paragrafo6b: 'Photo: CSIRO ScienceImage 10564',
    paragrafo7: ''
  },
  islaDelCocoInstitucional: {
    titulo: 'Coco Island',
    paragrafo1: 'Coco is an oceanic island located on the Pacific Ocean at 532km from the coast of Costa Rica. Due to isolation, geolgraphic location, and size, it is of high relevance for the conservation of marine and terrestrial biodiversity. Species richness is very high, and many species are endemic, i.e., occur only on the island. However, the island’s ecosystems are threatened by the introduction of several animals and plants.',
    paragrafo2: 'Pigs, deer, goats, cats and rats currently threaten native species and impact the functioning of local ecosystems, which evolved in the absence of herbivore animals or any other mammal. Additionally, several plant species were introduced for cultivation. For example, coffee plantations led to changes in vegetation structure and create competition with native and endemic plants.',
    paragrafo3: 'Coffea arabica - café',
    paragrafo3b: 'Photo: Eduardo Chacón Madrigal',
    paragrafo4: 'Felis silvestris - Gato doméstico',
    paragrafo4b: 'Photo: Sakthibalan',
    paragrafo5: 'Odocoileus virginianus - venado cola branca',
    paragrafo5b: 'Photo: Hannia Rodríguezme',
    paragrafo6: 'Rattus rattus - rata negra',
    paragrafo6b: 'Photo: CSIRO ScienceImage 10564'
  },
  mascotesInstitucional: {
    titulo: 'Pets and ornamental fishes',
    paragrafo1: 'The pet trade is one of the main pathways of introduction of invasive alien species, given the high diversity of species and quantity of individuals. People buy reptiles, birds or fishes on websites or in specialized shops. Although most animals are kept in confinement, some end up released because the owners can no longer care for them, or escape.',
    paragrafo2: 'Once released in natural areas or urban parks, these animals can establish and invade, pass on diseases or parasites or compete with native animals, generating negative impacts on natural ecosystems. Animal species are exported as pets, but also as live food or for decoration in aquaria or terraria where pets are kept.',
    paragrafo3: '',
    paragrafo4: 'Carassius auratus - carpa',
    paragrafo4b: 'Photo: Felipe Aira',
    paragrafo5: 'Lonchura malacca - munia tricolor',
    paragrafo5b: 'Photo: Shino Jacob Koottanad',
    paragrafo6: 'Poecilia reticulata - Guppy',
    paragrafo6b: 'Photo: Vincent Eisfeld ',
    paragrafo7: 'Xiphophorus variatus - Plati variado',
    paragrafo7b: 'Photo: Marrabbio'
  },
  plantasOrnamentaisInstitucional: {
    titulo: 'Ornamental plants',
    paragrafo1: 'Ornamental plants are used for gardening and landscaping in urban areas, interior design and flower production. The ornamental plant trade is a growing economic activity of high monetary relevance around the globe. Due to globalization and high demand, the ornamental plant trade generates intense movement of plants between countries and regions. The introduction of such plants without proper screening may cause ecological damage. The introduction of ornamental plants is currently the main source of invasive alien plants in many countries.',
    paragrafo2: 'More than 50% of all plant species and 85% of alien invasive trees in Costa Rica were originally introduced as ornamentals. Ecological damage can be irreversible and generate economic losses. The development of legal regulations for the ornamental plant trade is currently a goal of sustainability policies in many countries.',
    paragrafo3: '',
    paragrafo4: 'Impatiens hawkeri - china',
    paragrafo4b: 'Photo: Eduardo Chacon Madrigal',
    paragrafo5: 'Spathodea campanulate - Llama del bosque',
    paragrafo5b: 'Photo: Eduardo Chacon Madrigal',
    paragrafo6: 'Thunbergia alata - ojo de poeta',
    paragrafo6b: 'Photo: Eduardo Chacon Madrigal'
  },
  projetosInstitucional: {
    titulo: 'Projects',
    paragrafo1: 'En esta sección se incluye información acerca de los proyectos actuales o anteriores enfocados en el estudio y el manejo de las especies exóticas invasoras presentes en Paraguay. Si participa o participó de algún proyecto referido a estas especies, por favor ayude a incorporarlo enviando la información correspondiente a',
    paragrafo2_1: '',
    paragrafo2_2: 'Please contribute data so we can keep the database up to date.',
    paragrafo2_3: '',
    paragrafo3: 'Please use the following format to cite data from this system:',
    paragrafo4_1: 'Costa Rica National Invasive Alien Species Database. Universidad de Costa Rica. Checked on DD/MM/AAA.',
    paragrafo4_2: 'Accessed on __/__/____ (date)'
  },
  referenciasInstitucional: {
    titulo: 'References',
    paragrafo1: 'Las publicaciones e informes técnicos citados aquí fueron utilizados para sumar información acerca de las especies presentes en el territorio nacional, su comportamiento y efectos. Algunos incluyen datos acerca de las opciones de manejo de las distintas especies, así como puntos de ocurrencia en el país. Si es autor o conoce publicaciones en la materia que aún no están incluidas en esta sección, por favor ayude a incorporarlas enviando las citas correspondientes a',
    paragrafo2: '',
    paragrafo3: 'Contribute with data',
    paragrafo4: 'Please use the following format to cite data from this system:',
    paragrafo5_1: 'Costa Rica National Invasive Alien Species Database. Universidad de Costa Rica. Checked on DD/MM/AAA.',
    paragrafo5_2: 'Accessed on __/__/____ (date)'
  },
  usoFlorestalInstitucional: {
    titulo: 'Forestry',
    paragrafo1: 'Several non-native species were introduced in Brazil after 1950 by government initiatives to develop a forestry industy for pulp, paper, wood and other subproducts. Some of these species became invasive, especially pines which have been distributed to many ecosystems. These species require continuous control to prevent them from escaping plantations and causing environmental damage. Forest certification requires companies to control invasions, and such trees have to be eliminated from natural areas, roadsides and railways, riparian forests, and other habitat types where they are not cultivated.',
    paragrafo2: 'The genus Pinus is considered the most invasive genus of tree in the southern hemisphere! Pines displace native vegetation in open ecosystems and use up more water than native plants.',
    paragrafo3: 'Learn more',
    paragrafo4: 'Pinus sp. - pine',
    paragrafo4b: 'Photo by: The Horus Institute',
    paragrafo5: 'Acacia mearnsii - black wattle',
    paragrafo5b: 'Photo by: The Horus Institute',
    paragrafo6: 'Acacia mangium - acacia',
    paragrafo6b: 'Photo by: The Horus Institute'
  },
  contatoInstitucional: {
    cabecalhoTitulo: 'Contact',
    contato: 'Contact',
    p1: 'Fill all the fields in the form below:',
    nome: 'Name',
    email: 'E-mail',
    instituicao: 'Institution',
    mensagem: 'Message',
    enviar: 'Send'
  },
  colaboreDados: {
    cabecalhoTitulo: 'Collaborate with information',
    paragrafo1: 'Our database is kept up to date with support from experts and nature lovers throughout the national territory. Before being published, all information provided is validated according to criteria adopted at the regional level.',
    paragrafo2: 'You can provide data on species not yet listed in our database or on new places of occurrence by downloading our app (Invasoras AR) or sending an e-mail to',
    paragrafo2b: 'You can also send information on new publications or projects on biological invasions in Costa Rica. Data providers that contribute regularly may receive a username and password to upload information directly to the database.',
    paragrafo3: 'Help us face the challenge of invasive alien species based with complete, up-to-date and reliable information!',
    paragrafo4: 'Thank you!'
  }
}
