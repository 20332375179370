<template>
  <div>
    <InstitucionalHeaderSingle :title="`${$t('colaboreDados.cabecalhoTitulo') }`" :classProp="'header-single header-colabore'" />
    <div class="container">
      <div class="row">
        <div class="col-sm col-lg-10 py-5 mb-5">

          <p>{{ $t('colaboreDados.paragrafo1') }}</p>

          <p>{{ $t('colaboreDados.paragrafo2') }} <a href="mailto:edchacon@gmail.com">edchacon@gmail.com</a>.
            {{ $t('colaboreDados.paragrafo2b') }}</p>

          <p>{{ $t('colaboreDados.paragrafo3') }}</p>
          <p>{{ $t('colaboreDados.paragrafo4') }}</p>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import InstitucionalHeaderSingle from '@/components/institucional/InstitucionalHeaderSingle'
export default {
  name: 'ColaboreDados',
  components: { InstitucionalHeaderSingle }
}
</script>
