export const es = {
  message: {
    requiredField: 'Campo obligatorio',
    noRecords: '¡No se encontraron registros!',
    confirmDel: '¿Está seguro de que quiere borrar?',
    confirmApproval: '¿Está seguro de que desea aprobar esta ocurrencia?',
    noResultsFor: 'No hay resultados para'
  },
  login: {
    username: 'Usuario',
    password: 'Contraseña',
    login: 'Login',
    entrar: 'Iniciar sesión',
    descricao: 'Ingrese sus datos de acceso en el siguiente formulario:'
  },
  exportarFicha: {
    author: 'autor',
    biodiversity_impact: 'impacto sobre la biodiversidad',
    chemical_control: 'control_químico',
    class: 'Clase',
    common_name: 'nombre_común',
    control_biological: 'control_biológico',
    data_bibliography: 'datos_bibliografía',
    economic_impact: 'impacto_económico',
    economic_use: 'uso_económico',
    family: 'familia',
    health_impact: 'impacto_salud',
    intro_data: 'datos_introducción',
    intro_description: 'descripción_introducción',
    invasion_preferential_environments: 'ambientes_preferenciales_invasión',
    kingdom: 'Reino',
    location: 'localidad',
    morphophysiology_description: 'descripción_morfofisiología',
    native_distribution_area: 'áreas_nativa_de_distribución',
    natural_environment: 'ambiente_natural',
    order: 'Órden',
    origin: 'origen',
    phyllum: 'Phyllum',
    physical_control: 'control_físico',
    prevention: 'prevención',
    scientific_name: 'nombre_científico',
    social_impact: 'impacto_social',
    species_id: 'id_especie',
    voc_cause_introduction: 'voc_causa_de_introducción',
    voc_diet: 'voc_dieta',
    voc_dispersal_routes: 'voc_rutas_dispersión',
    voc_dispersion_vectors: 'voc_vectores_dispersión',
    voc_form: 'voc_forma',
    voc_introduction_type: 'voc_tipo_introducción',
    voc_reproduction: 'voc_reproducción',
    voc_spread: 'voc_dispersión',
    world_invasive_place: 'sitios_invasión_mundo'
  },
  label: {
    voltar: 'Vuelve',
    outro: 'Otro',
    filtros: 'Filtros',
    acesseBaseDados: 'Acceda a la base de datos',
    conhecaNossaRede: 'Red Latino Americana',
    baseDadosNacional: 'Bases de datos nacionales',
    politicaDeDados: 'Política de datos',
    comiteCientifico: 'Comité científico',
    colabore: 'Aportar datos',
    especiesMarinhas: 'Especies marinas',
    peixesAquario: 'Peces de acuario',
    peixesAquicultura: 'Peces de acuicultura',
    pescaDesportiva: 'Pesca deportiva',
    pets: 'Animales de compañía',
    plantasForrageiras: 'Plantas forrajeras',
    plantasOrnamentais: 'Plantas ornamentales',
    usoFlorestal: 'Uso forestal',
    tutorial: 'Tutorial',
    especies: 'Especies',
    colaboradores: 'Colaboradorxs',
    referencias: 'Referencias',
    projetos: 'Proyectos',
    saibaMais: 'Más info',
    contida: 'Contenida',
    casual: 'Casual',
    estabelecida: 'Establecida',
    invasora: 'Invasora',
    exibirListagem: 'Mostrar listado',
    concluir: 'Terminar',
    fechar: 'Cerrar',
    próximo: 'Próximo',
    anterior: 'Anterior',
    clickAqui: 'Clic aquí',
    numRegistrosEncontrados: 'Número de registros encontrados:',
    exibir10Reg: 'Mostrar 10 registros',
    exibir25Reg: 'Mostrar 25 registros',
    exibir50Reg: 'Mostrar 50 registros',
    exibir100Reg: 'Mostrar 100 registros',
    limpar: 'Limpiar',
    buscar: 'Buscar',
    addColaborador: 'Agregar colaborador/a',
    nome: 'Nombre',
    sobrenome: 'Apellido',
    aprovarColaborador: 'Aprobar colaborador/a',
    salvarColaborador: 'Salvar colaborador/a',
    titulo: 'Título',
    cargo: 'Cargo',
    especialidade: 'Especialidad',
    instituicao: 'Institución',
    endereco: 'Dirección',
    estado: 'Estado',
    cidade: 'Ciudad',
    pais: 'País',
    email: 'E-mail',
    telefone: 'Teléfono',
    telefone2: 'Teléfono 2',
    websiteURL: 'Website URL',
    midiaSocial1: 'Red social 1',
    midiaSocial2: 'Red social 2',
    midiaSocial3: 'Red social 3',
    observacoesAdicionais: 'Observaciones adicionales',
    adicionar: 'Agregar',
    editando: 'Editando',
    acoes: 'Acciones',
    usuarixs: 'Usuarios',
    adicionarUsuario: 'Agregar usuarix',
    descricao: 'Descripción',
    ativo: 'Activo',
    sim: 'Sí',
    nao: 'No',
    salvarUsuarix: 'Salvar usuarix',
    senha: 'Contraseña',
    observacoes: 'Observaciones',
    grupos: 'Grupos',
    basico: 'Básico',
    administrator: 'Administrador',
    taxonomia: 'Taxonomía',
    nomeCientifico: 'Nombre científico',
    adicionarEspecie: 'Agregar especie',
    genero: 'Género',
    familia: 'Familia',
    ordem: 'Orden',
    classe: 'Clase',
    phylumDivisao: 'Phylum / División',
    reino: 'Reino',
    phylum: 'Phylum',
    origem: 'Origen',
    descricaoInvalida: 'Descripción no válida',
    selecioneFamilia: 'Por favor, seleccione una familia.',
    selecioneOrdem: 'Por favor, seleccione un Orden.',
    selecioneClasse: 'Por favor, seleccione una Clase.',
    selecioneDivisao: 'Por favor, seleccione una División.',
    selectioneReino: 'Por favor, seleccione un Reino.',
    selectioneGenero: 'Por favor, seleccione un género.',
    addReferencia: 'Agregar referencia',
    autoresEX: 'Autorxs (ex. ZILLER, S. R.; DECHOUM, M. S.)',
    aprovarReferencia: 'Aprobar referencia',
    salvarReferencia: 'Salvar referencia',
    autorxsEX: 'Autorxs (ex. ZILLER, S. R.; DECHOUM, M. S.)',
    autores: 'Autorxs',
    local: 'Localidad (Ex. Salvador, BA  /  Sudáfrica: Ciudad del Cabo)',
    localFiltro: 'Localidad',
    edicao: 'Edición (ex. 2 ed.)',
    editora: 'Editorial',
    revista: 'Revista',
    anoPublicacao: 'Año de publicación',
    meioDivulgacao: 'Medio de comunicación',
    volume: 'Volumen (ex. v. 3)',
    numero: 'Número (ex. n. 28)',
    pagina: 'Páginas (ex. p. 25-34  /  245p.)',
    url: 'DOI / URL',
    palavraChave: 'Palabra clave',
    anoPublic: 'Año publicación',
    addProjeto: 'Agregar proyecto',
    aprovarProjeto: 'Aprobar Proyecto',
    salvarProjeto: 'Salvar proyecto',
    contato: 'Contacto',
    areaGeografica: 'Área geográfica',
    objetivos: 'Objetivos',
    breveDescricao: 'Breve descripción',
    dataInicio: 'Fecha de inicio',
    dataTermino: 'Fecha de finalización',
    comentarios: 'Comentarios',
    anoInicio: 'Año de inicio',
    anoTermino: 'Año de finalización',
    ocorrencias: 'Ocurrencias',
    exportarDados: 'Exportar datos',
    addOcorrencia: 'Agregar ocurrencia',
    visualizarOcorrencia: 'Ver ocurrencia',
    alterarOcorrencia: 'Modificar ocurrencia',
    ocorrenciasVinculadas: 'Ocurrencias vinculadas',
    areaProtegida: 'Área protegida',
    especie: 'Especie',
    aprovarOcorrencia: 'Aprobar ocurrencia',
    salvarOcorrencia: 'Salvar ocurrencia',
    colaborador: 'Colaborador(a)',
    municipio: 'Municipalidad',
    municipioSede: 'Municipalidad (sede)',
    municipioCentroide: 'Municipalidad (centroide)',
    referenciaLocal: 'Referencia local',
    referencia: 'Referencia',
    latitude: 'Latitud',
    longitude: 'Longitud',
    coordenadasCorrespondem: 'Las coordenadas corresponden a',
    distanciaAproximada: 'Distancia aproximada',
    ambiente: 'Ambiente',
    baciaHidro: 'Cuenca hidrográfica',
    descricaoInvasao: 'Descripción de la invasión',
    abundancia: 'Abundancia',
    situacaoPopulacional: 'Situación poblacional',
    manejo: 'Manejo',
    dataEntrada: 'Fecha de ingreso',
    dataObservacao: 'Fecha de observación (ej. 2020 o 13/05/2020)',
    validado: 'Validado',
    especieMuseuHerbario: 'Es un ejemplar de museo o de herbario',
    selecioneTipo: 'Seleccione el tipo',
    herbario: 'Herbario',
    museu: 'Museo',
    numeroExemplaInstituicao: 'Número de ejemplar (institución)',
    numeroExemplarColetor: 'Número de ejemplar (colector)',
    selecioneOpcao: 'Seleccione una opción…',
    pointList1: 'A un punto de ocurrencia de uno o más individuos de la especie',
    pointList2: 'Al punto central de un grupo de organismos de la especie',
    pointList3: 'A un punto próximo de la ocurrencia, ubicado a una distancia aproximada de :',
    coordinatesSourceList1: 'A - Una unidad política o administrativa',
    coordinatesSourceList2: 'B - El sitio real de ocurrencia',
    ultimoRegistroBaseDados: 'Últimos registros en la base de datos',
    ultimosAcessos: 'Últimos accesos',
    fichaTecnicaEspecie: 'Ficha técnica de las especies',
    fichaTecnicaEspecieMMA: 'Ficha técnica de las especies MMA',
    fichaTecnicaEspecieMMA2: 'Ficha técnica de las especies MMA versión 2',
    invasoras: 'Invasora',
    contidasBrasil: 'Contenida',
    ausentesBrasil: 'Ausente',
    deficiencia: 'Datos insuficientes (DD)',
    formatosDisponiveisExportacao: 'Formatos disponibles para exportación:',
    biologiaEcologia: 'Biología y ecología',
    usoEconomico: 'Uso económico',
    usoEconomicoDescricao: 'Uso económico - descripción',
    invasaoBiologica: 'Invasión biológica',
    impactos: 'Impactos',
    habitat: 'Hábitat',
    nomeCientificoSinonimos: 'Nombre científico + sinónimos',
    nomesPopulares: 'Nombres comunes',
    reproducao: 'Reproducción',
    dispersao: 'Dispersión',
    dieta: 'Dieta',
    formaBiologica: 'Forma biológica',
    ambientesPreferenciaisInvasao: 'Ambientes preferenciales de invasión',
    tipoIntroducao: 'Tipo de introducción',
    causaIntroducao: 'Causa de introducción',
    localIntroducao: 'Sitio de introducción',
    anoIntroducao: 'Año de introducción',
    marinho: 'Marino',
    dulcicola: 'Agua dulce',
    terrestre: 'Terrestre',
    validarEspecie: 'Validar especie',
    salvarEspecie: 'Salvar especie',
    data: 'Data',
    confiabilidade: 'Confiabilidad',
    resultadosAnalisesRisco: 'Resultados del análisis de riesgo',
    risco: 'Riesgo',
    areaOrigem: 'Área de orígen',
    areaDistribuicaoNatural: 'Área de distribución natural',
    ambientesNaturais: 'Ambientes naturales',
    descricaoEspecie: 'Descripción de la especie',
    ambientesPreferenciaisInvasaoDescricao: 'Ambientes preferenciales de invasión - descripción',
    outrosLocaisOndeEspecieInvasora: 'Otros sitios donde la especie se comporta como invasora',
    impactosEcologicos: 'Impactos ambientales',
    impactosEconomicos: 'Impactos económicos',
    impactosSociais: 'Impactos sociales',
    impactosSaude: 'Impactos sobre la salud',
    categoriaEICAT: 'Categoria EICAT',
    mecanismosImpactoEICAT: 'Mecanismos de impacto EICAT',
    nivelConfiancaAvaliacaoEICAT: 'Nivel de confianza de la evaluación EICAT',
    referenciaEICAT: 'Referencia EICAT',
    dataEICAT: 'Fecha EICAT (ej. 2021)',
    categoriaSEICAT: 'Categoria SEICAT',
    mecanismosImpactoSEICAT: 'Mecanismos de impacto SEICAT',
    nivelConfiancaAvaliacaoSEICAT: 'Nivel de confianza de la evaluación SEICAT',
    referenciaSEICAT: 'Referencia SEICAT',
    dataSEICAT: 'Fecha EICAT (ej. 2021)',
    digitarSomenteAno: 'Ingresar solamente el año - 2021',
    viasCDBCategorias: 'Vías CDB - categorías',
    viasIntroducao: 'Vías de introducción y dispersión',
    viasVetoresCDBSubcategorias: 'Vías y vectores CDB - subcategorías',
    vetoresIntroducao: 'Vectores de introducción y dispersión',
    introducao: 'Introducción',
    adicionarIntroducao: 'Agregar introducción',
    ano: 'Año',
    descricaoIntroducao: 'Descripción de la introducción',
    salvar: 'Salvar',
    cancelar: 'Cancelar',
    excluir: 'Eliminar',
    medidasPreventivas: 'Medidas de prevención',
    medidasPreventivasMMA: 'Medidas de prevención MMA',
    medidasDeteccaoPrecoce: 'Medidas de detección temprana',
    controleMecanico: 'Control mecánico',
    controleQuimico: 'Control químico',
    controleBiologico: 'Control biológico',
    distribuicaoPais: 'Distribución en el país',
    vejaMapaOcorrencias: 'Vea el mapa de ocurrencias',
    ecologiaUso: 'Ecología y uso',
    introducaoDispersao: 'Introducción y dispersión',
    analiseRisco: 'Análisis de riesgo',
    nomeComum: 'Nombre común',
    foto: 'Foto',
    credito: 'Crédito',
    sinonimos: 'Sinónimos',
    autor: 'Autor',
    subespecie: 'Subespecie',
    autorSubespecie: 'Autor - subespecie',
    pendenteAnalise: 'Pendiente de análisis',
    cancelarVoltar: 'Cancelar / Volver',
    versaoAnterior: 'Versión anterior',
    versaoAtual: 'Versión actual',
    colaboradorxs: 'Colaboradorxs',
    consultaTaxonomiaListaCompletaEspecies: 'Taxonomía - Lista completa de especies',
    consultaEspeciesOrigemAmbientes: 'Especies, origen, ambientes de invasión e impactos',
    consultaEspeciesManejo: 'Especies y manejo',
    consultaParaGerarFichaTecnica: 'Ficha técnica de la especie sin ocurrencias',
    consultaEspeciesOcorrencias: 'Especies y ocurrencias',
    exportarDadosConsultas: 'Exportar consultas',
    ocorrenciasApp: 'Ocurrencias APP',
    dataCadastro: 'Fecha de registro',
    criadoPor: 'Creado por',
    nivelCerteza: 'Nivel de certidumbre',
    byApp: 'Por aplicación móvil',
    verDados: 'Ver datos',
    appuser: 'APPUser',
    profissao: 'Profesión',
    colobadorJaCadastrado: 'Colaborador ya registrado',
    especialidadeApp: 'Especialidad APP',
    cep: 'Código postal',
    categoriaCDB: 'Categoría CBD',
    subCategoriaCDB: 'Subcategoría CDB',
    mensagemEnviada: 'Mensaje enviado correctamente.'
  },
  home: {
    destaque1: 'Base de Datos Nacional sobre Especies Exóticas Invasoras',
    destaqueDescricao1: 'Bienvenidx! Esta base de datos cuenta con la colaboración de muchas personas comprometidas con la conservación ambiental. Te invitamos a recorrer nuestras páginas!',
    destaque2: 'Desde 2005 documentando especies exóticas invasoras y compartiendo la información online!',
    destaqueDescricao2: 'Este sistema de bases de datos fue desarrollado con recursos nacionales e internacionales. Cuenta con un Comité Científico de apoyo y se actualiza con la colaboración de científicos de diferentes campos.',
    destaque3: 'Nuestro propósito',
    destaqueDescricao3: 'Proveer datos sobre especies exóticas invasoras en Costa Rica para cooperar con la gestión pública, apoyar programas de manejo, auxiliar en la investigación científica e informar al público en general.',
    destaque4: '¿Cómo funciona?',
    destaqueDescricao4: 'Este es una base de datos de acceso abierto. Utilice la información disponible, pero por favor cite este site como referencia en su trabajo. Los proveedores regulares de datos pueden solicitar una cuenta para contrbuir con datos.',
    titulo: 'Busque especies, filtre y exporte datos',
    conteudo: 'Aquí encontrará información acerca del área nativa de distribución de las especies, características biológicas y ecológicas, hábitats invadidos, lugares donde la especie está presente y donde invade en el mundo y en Costa Rica, incluyendo un mapa de ocurrencias en el país, historia de introdución y uso, vectores y rutas de introducción y de dispersión, información acerca de su manejo y referencias. Utilice los filtros para buscar la información deseada y expórtela en distintos formatos.',
    conteudoEspecies: 'Listas de especies, características ecológicas, rango nativo de distribución, lugares donde la especie invade a nivel global, vectores y rutas de dispersión, análisis de riesgo, usos, opciones de manejo, localidades de ocurrencia en Costa Rica, referencias.',
    conteudoColaboradores: 'Registro de proveedores de datos. Si quiere entrar en contacto con quien proveyó la información sobre alguna especie o un sitio de ocurrencia en particular busque aquí.',
    conteudoReferencias: 'Lista de las referencias relacionadas con las especies incluidas en la base de datos.',
    conteudoProjetos: 'Proyectos de investigación y de manejo de especies exóticas invasoras en la Costa Rica.',
    titulo1: 'Conozca las especies exóticas invasoras.',
    conteudo1: 'Listas de especies exóticas invasoras organizadas según el motivo o vía de introducción a Costa Rica, usos o efectos sobre los ecosistemas. Las páginas incluyen una explicación sobre cada grupo de especies y vínculos para fuentes de información adicionales.'
  },
  areaParceiros: {
    titulo: 'Red de Bases de Datos sobre Especies Exóticas Invasoras:',
    argentina: 'Argentina',
    paraguai: 'Paraguay',
    uruguai: 'Uruguay'
  },
  redeBaseDeDados: {
    cabecalhoTitulo: 'Red Latino-Americana y del Caribe',
    paragrafo1: 'El origen de esta red de bases de datos se remonta al año 2004, como parte de la Red Temática sobre Especies Exóticas Invasoras de la Red Interamericana de Información sobre Biodiversidad (I3N-IABIN), establecida como una iniciativa de la Cumbre de las Américas.',
    paragrafo2: 'Cada país integrante de I3N designó un líder nacional cuya función era desarrollar una base de datos nacional sobre estándares comunes y compartir información sobre especies exóticas invasoras:',
    paragrafo2_1: '',
    marcador1: 'Argentina: Dr. Sergio M. Zalba, Profesor de la Universidad Nacional del Sur e investigador del CONICET (desde 2004);',
    marcador2: 'Brasil: Dra. Sílvia R. Ziller, Fundadora y Directora Ejecutiva del Instituto Horus de Desarrollo y Conservación Ambiental (desde 2004);',
    marcador3: 'Chile: Dr. Aníbal Pauchard, Professor, Facultad de Ciencias Forestales, Universidad de Concepción.',
    marcador4: 'Costa Rica: PhD. Eduardo Chacón-Madrigal, Profesor de la Universidad de Costa Rica. Investigador en el Centro de Investigación en Biodiversidad y Ecología Tropical. Co-coordinador de Flora Invasive and Naturalized of Central America.',
    marcador5: 'Ecuador: Dr. Diego Inclán, Director Ejecutivo del Instituto Nacional de Biodiversidad (desde 2021) y Francisco José Prieto Albuja;',
    marcador5_1: 'Honduras: Dra Lilian Ferrufino, Universidad Nacional Autónoma de Honduras;',
    marcador5_2: 'Jamaica: Dra. Suzanne Davis, Clearing-House Mechanism, Institute of Jamaica;',
    marcador5_3: 'Paraguay: Hugo Fernando del Castillo, Guyra Paraguay (desde 2006);',
    marcador5_4: 'Uruguay: Dr. Ernesto Brugnoli, Profesor de la Universidad de la República, Montevideo (desde 2005), y el MSc. Marcelo Iturburu, Coordinador del Comité Nacional de Especies Exóticas Invasoras del Ministerio de Ambiente (desde 2018).',
    paragrafo3: 'La iniciativa IABIN se extendió hasta el año 2011 con financiamiento del Fondo para el Ambiente Mundial (GEF). Más allá del final de este proyecto, algunos de los líderes nacionales de la red mantuvieron las bases de datos nacionales en funcionamiento (Argentina, Brasil, Jamaica, Paraguay y Uruguay).',
    paragrafo4: 'En el año 2021, se recibió el apoyo de la iniciativa BioBridge, de la Secretaría de la Convención sobre Diversidad Biológica (CDB), para reforzar y actualizar las bases de datos de Argentina, Brasil, Paraguay y Uruguay. La interfase fue actualizada y se incorporaron nuevas funcionalidades.',
    paragrafo5: 'Las personas que han desarrollado la Base de Dados son:',
    marcador6: 'El Programador João Scucato, de Curitiba – PR, Brasil, que desarrolló la primera versión en Microsoft Access, 2004-2005;',
    marcador7: 'El programador Alejandro Moreno, de Argentina, está involucrado con el mantenimiento de la red desde 2006. Mantuvo copias de los archivos y rescató los contenidos de Paraguay y Uruguay para la nueva edición, además de desarrollar la',
    marcador7_1: 'Plataforma Común',
    marcador7_2: 'de los países de la red;',
    marcador8: 'El diseñador Rafael Moura y el programador Thiago Lôbo, vinculados la',
    marcador8_1: 'Plataforma Vibbra',
    marcador8_2: 'en Florianópolis – SC, Brasil;',
    marcador9: 'El programadores Leonardo Rotondano, para el desarrollo de la aplicación para teléfono móvil de reporte de ocurrencias de especies exóticas.',
    paragrafo6: 'La red espera ampliar su cobertura territorial incorporando otros países de la región de manera de ampliar la disponibilidad de información sobre especies exóticas invasoras para el conocimiento público, la gestión gubernamental y el manejo.',
    paragrafo7: 'Red de Bases de Datos sobre Especies Exóticas Invasoras:',
    paragrafo8: '',
    paragrafo9: ''
  },
  baseDadosNacional: {
    cabecalhoTitulo: 'Base de datos nacional',
    titulo: 'La Base de Datos Nacional de Espécies Exóticas Invasoras',
    paragrafo1: 'El desarrollo de esta base de datos inició en el 2006 con la publicación de un listado de especies de plantas introducidas en Costa Rica',
    paragrafo1_1: 'elaborada por Eduardo Chacón Madrigal y Guido Saborío Rodríguez que en ese momento formaban parte de la Asociación para la Conservación y Estudio de la Biodiversidad (ACEBIO). Este listado de plantas fue la base para el desarrollo de la base de datos de especies invasoras de Costa Rica que formó parte de la red de información sobre especies exóticas invasoras, entonces llamada red I3N (IABIN Invasives Information Network). La red estaba coordinada, en aquel momento, por Andrea Grosse, una bióloga muy dedicada a la red que hizo posible el desarrollo de la estructura de la base de datos y de varios otros productos, como análisis de riesgos, manuales y cursos de formación sobre el tema. Otra persona que tuvo un papel decisivo en el crecimiento de la red fue la Dra. Annie Simpson, del USGS, entonces responsable de la Red Mundial de Información sobre Especies Invasoras (GISIN), cuyo objetivo era establecer normas de información sobre las especies exóticas invasoras para facilitar la comunicación entre las bases de datos mundiales y de los distintos países. Los recursos para el desarrollo de la base de datos fueron proporcionados por el Banco Mundial (GEF) para un proyecto coordinado por el Servicio Geológico de los Estados Unidos (USGS) que tenía como objetivo establecer la IABIN - Red Interamericana de Información sobre Biodiversidad.',
    paragrafo2: 'La Red I3N existió entre los años 2001 y 2011, cuando finalizó el proyecto. Durante este periodo, la estructura de la base de datos se difundió a 21 países de América por medio de cursos de formación para la gestión del tema a nivel nacional y de la propia base de datos: Argentina, Brasil, Uruguay, Chile, Paraguay, Bolivia, Perú, Ecuador, Colombia, Venezuela, Surinam, República Dominicana, Bahamas, Jamaica, Santa Lucía, Panamá, Costa Rica, Honduras, El Salvador, Nicaragua y Guatemala.',
    paragrafo3: 'Lamentablemente, la mayoría de las bases de datos nacionales se han perdido con el tiempo. Las bases de datos que se han mantenido desde el principio son la de Argentina (Universidad Nacional del Sur, Administrador Dr. Sergio Zalba); la de Brasil (Instituto Horus, Dra. Silvia Ziller y Dra. Michele de Sá Dechoum), la de Uruguay (Universidad de la República en Montevideo, Dr. Ernesto Brugnoli) y la de Jamaica (Institute of Jamaica, Dra. Suzanne Davis). La cooperación entre estas bases de datos nunca ha sido interrumpida por sus administradorxs.',
    paragrafo4: 'En 2011 se actualizó la base de datos desde el formato original de Microsoft Access al formato de software libre MySQL. En aquella oportunidad se introdujeron numerosas correcciones y mejoras basadas en los informes de experiencia de los usuarios de varios países. La nueva versión se distribuyó a los países que formaban parte de la red.',
    paragrafo5: 'Diez años después, en 2021, la Iniciativa BioBridge de la Secretaría del Convenio Internacional sobre la Diversidad Biológica en Montreal, Canadá, aportó recursos para una nueva actualización para Argentina, Brasil, Uruguay y Paraguay. En el 2023 a través de la misma Iniciativa BioBridge se incorpora la base de datos de Chile, Jamaica, Honduras y Costa Rica. En esta ocasión la base de datos de Costa Rica se incorpora con la colaboración de Eduardo Chacón Madrigal ahora asociado como investigador al Centro de Investigación en Biodiversidad y Ecología Tropical de la Universidad de Costa Rica. Una vez más, se han incorporado varias mejoras para facilitar a los usuarios la introducción, edición y exportación de datos. Esperamos que esta versión sea más accesible y fácil de usar, y que la base de datos nacional sirva de base para la gestión pública, el manejo en campo y que permita la elaboración de muchos artículos técnicos y científicos. También esperamos que el conocimiento sobre especies exóticas invasoras estimule el uso de especies nativas, especialmente para proteger la diversidad biológica y la resiliencia de los ecosistemas naturales en Costa Rica y en América.',
    paragrafo6: 'Agradecemos a las personas que han colaborado con la Base de Datos Nacional, tanto por haber aportado datos como por haber ayudado a validar las especies y las ocurrencias, y por todo el apoyo que se ha necesitado. La riqueza de los datos disponibles es, sin duda, el resultado de un importante esfuerzo conjunto.',
    paragrafo7: ''
  },
  politicaDeDados: {
    cabecalhoTitulo: 'Política de datos',
    titulo: 'Conceptos y referencias',
    criterio: 'Criterios para la inclusión de especies',
    avaliacao: 'Evaluación y revisión de datos',
    validacao: 'Validación de datos',
    paragrafo1: 'A los efectos de este sistema de datos se adoptan las siguientes definiciones, de acuerdo con el Convenio de Diversidad Biológica:',
    marcador1: 'especie nativa: aquella que se encuentra dentro del área de distribución geográfica donde evolucionó y forma parte de una comunidad biológica en equilibrio;',
    marcador2: 'especie exótica: aquella que se encuentra fuera de su área de distribución natural pasada o presente producto de la intervención humana;',
    marcador3: 'especie exótica invasora: toda especie exótica que afecta o amenaza hábitats, ecosistemas o especies causando alteraciones en los ambientes naturales.',
    paragrafo2: 'La Base de Datos Nacional de Especies Exóticas Invasoras de Costa Rica tiene enfoque en las especies exóticas invasoras que ya están presentes en el país. Las especies registradas están siempre relacionadas a por lo menos una ocurrencia geográfica en Costa Rica.',
    paragrafo3: '',
    paragrafo4: 'El sistema de clasificación de ambientes terrestres utilizado es el Sistema de Zonas de Vida de Holdridge (Holdridge 1967). Los términos utilizados para ambientes marinos y de agua dulce fueron definidos con especialistas en esas áreas. El vocabulario controlado utilizado en los campos de ambientes preferenciales de invasión, rutas y vectores de dispersión, formas biológicas, impactos y métodos de control fueron definidos de acuerdo con los términos utilizados a por la Base de Datos Global sobre Especies Invasoras del Grupo Especialista en Especies Invasoras de la UICN',
    paragrafo5_1: 'Las referencias taxonómicas principales se toman de los sistemas propuestos por el Jardín Botánico de Missouri ',
    paragrafo5_2: 'The Plant List ',
    paragrafo5_3: 'y por el Sistema Integrado de Información Taxonómica ',
    paragrafo5_4: 'Referencias complementarias incluyen el Registro Mundial de Especies Marinas',
    paragrafo5_5: 'la Base de Datos Global sobre Especies Exóticas Invasoras',
    paragrafo5_6: 'y el Compendio de Especies Invasoras de CABI',
    paragrafo6: 'Para cada punto de ocurrencia las especies se califican como:',
    marcador4: '– cuando su presencia se limita o está restringida a sistemas de cultivo o cría o vive dependiendo de manera directa de las personas, por ejemplo como animal de laboratorio, de compañía o de acuario, planta de jardín, sin evidencias de escape de individuos en ese sitio.',
    marcador5: '– cuando la especie fue vista en ambientes naturales o seminaturales, fuera de sistemas de cultivo o cría, todavía sin evidencias de haber establecido un núcleo poblacional.',
    marcador6: '– cuando la especie se reproduce de manera efectiva, conformando una población autosostenible pero se mantiene en un área limitada, próxima al sitio de introducción local.',
    marcador7: '– cuando la especie avanza, ya sea de manera autónoma o auxiliada por vector antrópicos, hacia otros sitios más allá del punto de introducción local.',
    paragrafo7: 'Es importante notar que el estado de invasión es un atributo de la localidad de ocurrencia, no de las especies. De este modo, la misma especie puede comportarse como invasora en una localidad, estar contenida en otro sitio y haber sido detectada en la naturaleza en un tercero.',
    paragrafo8: 'Cada información de ocurrencia recibida queda siempre vinculada a la fuente, o sea, a la persona que la provió y/o a un trabajo científico, incluyendo unx o más autorxs. Otros datos son referenciados en los campos descriptivos a la medida de lo posible. En el caso de dudas, cuestionamientos o necesidad de más información sobre algún dato, la fuente puede ser indicada o consultada. El vínculo entre las informaciones y los proveedores de datos es, más que todo, reconocer la autoría y valorizar el trabajo y el apoyo de los colaboradorxs.',
    paragrafo8_en: '',
    paragrafo9_1: 'En el caso que tengas datos sobre especies exóticas invasoras para contribuir con la base de datos, por favor utilice la hoja Excel disponible en nuestro sitio web y envíela para ',
    paragrafo9_2: ' o haga contacto con nosostrxs.',
    paragrafo10: 'En caso de dudas o cuestionamientos, por favor envíe un correo para ',
    paragrafo11: 'Los datos son revisados continuamente a la medida que más informaciones sobre una especie son publicadas o comunicadas directamente por expertos. Puede entonces haber cuestionamiento sobre especies inseridas en la base de datos por cuenta de la distribución natural o de la capacidad de invasión, casos en que se busca apoyo de expertos en el grupo biológico específico para decidir si la especie debe ser removida. Algunas especies incluidas durante los primeros años de la base de datos fueron posteriormente removidas porque no había datos suficientes para atender a los criterios de inclusión o porque faltaban evidencias de invasión biológica. Eso puede ocurrir, por ejemplo, cuando se reconoce que una especie exótica es observada en un sitio por mucho tiempo, pero no existen evidencias de invasión a lo largo del tiempo, ni hay datos que confirmen el potencial de invasión en Costa Rica o en otras partes del mundo. En los casos de remoción de especies de la base de datos, los datos son almacenados separadamente y quedan en un listado de espera para el caso que nuevas revisiones indiquen que debe volver a ser incluida.',
    paragrafo12: 'La base de datos contempla especies exóticas invasoras de todos los grupos biológicos con potencial de impacto sobre la diversidad biológica y/o sobre ambientes naturales o seminaturales. Las mismas especies pueden generar impactos sobre la economía, valores sociales o culturales o sobre la salud humana o animal, pero el foco de este sistema es la conservación de la diversidad biológica y, por ello, no se incluyen en la base de datos especies exóticas que se comporten como malezas, plagas o patógenos de las personas o de los animales domésticos pero que no hayan sido detectadas todavía en la naturaleza o afectando a especies de la flora y fauna nativa y no tengan antecedentes internacionales en ese sentido.',
    paragrafo13: 'Cada vez que una especie es registrada en la base de datos, los datos complementarios son incluidos. A la medida que nuevos datos quedan disponibles, son incluidas nuevas informaciones y, principalmente, nuevas ocurrencias de la especie en el país. Esos datos son obtenidos de publicaciones técnicas y científicas, de colaboradores directos que trabajan en el terreno, de administradores y funcionarios de áreas protegidas o por observación y coleta de puntos directamente en el terreno. La inclusión de coordinadas geográficas es obligatoria para todos los registros.',
    paragrafo14: 'Los datos son revisados continuamente a la medida que más informaciones sobre una especie son publicadas o comunicadas directamente por expertos. Puede entonces haber cuestionamiento sobre especies inseridas en la base de datos por cuenta de la distribución natural o de la capacidad de invasión, casos en que se busca apoyo de expertos en el grupo biológico específico para decidir si la especie debe ser removida. Algunas especies incluidas durante los primeros años de la base de datos fueron posteriormente removidas porque no había datos suficientes para atender a los criterios de inclusión o porque faltaban evidencias de invasión biológica. Eso puede ocurrir, por ejemplo, cuando se reconoce que una especie exótica es observada en un sitio por mucho tiempo, pero no existen evidencias de invasión a lo largo del tiempo, ni  hay datos que confirmen el potencial de invasión en Brasil o en otras partes del mundo. En los casos de remoción de especies de la base de datos, los datos son almacenados  separadamente y quedan en un listado de espera para el caso que nuevas revisiones indiquen que debe volver a ser incluida.',
    paragrafo15: 'La confiabilidad de los datos es una de las principales preocupaciones de los administradores de este sistema. La validación de la información se evalúa en dos niveles principales: respecto de la correcta identificación de cada taxón y en referencia con la exactitud del sitio al que se refiere cada dato. Para eso se utilizan estándares y criterios consensuados con el resto de los sistemas que conforman la red de información sobre EEI.',
    paragrafo16: 'Criterios para validar la identidad taxonómica de cada taxón',
    marcador8: 'El proveedor del dato (autor de la publicación o colector del espécimen) puede considerarse un especialista del grupo en cuestión o tiene una experiencia equivalente?',
    marcador9: 'La ocurrencia está documentada mediante una fotografía que permite la identificación confiable del organismo en cuestión?',
    marcador10: 'La ocurrencia está documentada en una publicación indexada y con evaluación de pares o en un documento técnico realizado o revisado por especialistas?',
    marcador11: 'La información se corresponde con un ejemplar de herbario o con un espécimen depositado en una colección de museo?',
    marcador12: 'La ocurrencia corresponde a una especie que ya estaba registrada para el país?',
    marcador13: 'El grupo al que pertenece la especie tiene una taxonomía estable y bien conocida?',
    marcador14: 'Las características de la especie permiten una determinación relativamente simple?',
    paragrafo17: 'Una respuesta positiva a al menos una de las preguntas 1, 2, 3 o 4 lleva directamente a la recomendación de considerar al dato como validado. En caso de responder negativamente a estas cuatro preguntas, la validación se obtendrá solamente con respuestas positivas a las tres últimas (5, 6 y 7).',
    paragrafo18: 'Criterios para validar la ubicación asociada a del sitio donde decimos que se detectó una especie',
    marcador15: 'El dato incluye coordenadas geográficas? Se consideran tanto coordenadas tomadas directamente en el sitio de la observación como otras que correspondan a alguna referencia geográfica cercana (en el caso que esto se indique).',
    marcador16: 'Se incluye una descripción del sitio que permite su identificación confiable?',
    marcador17: 'La ocurrencia está documentada mediante una fotografía que permite la identificación confiable del sitio?',
    marcador18: 'Las coordenadas geográficas provistas coinciden con la ubicación del sitio descripto y/o fotografiado?',
    marcador19: 'La especie ya está citada para el país y, en ese caso, la localidad está incluida dentro de su rango de distribución conocido o en un sitio que resulta compatible con los sitios de presencia conocida y con su capacidad de dispersión?',
    marcador20: 'Las condiciones ecológicas del sitio, inferidas en función de la localización indicada, resultan compatibles con los requerimientos generales de nicho de la especie?',
    paragrafo19: 'El dato se considera validado, desde el punto de vista de su ubicación geográfica, si:',
    marcador21: 'se responde de manera afirmativa a las preguntas 1, 2 y/o 3, y 4 (el dato incluye coordenadas geográficas, se brinda una referencia inequívoca del sitio y/o se provee una fotografía que permite la identificación confiable de lugar y las coordenadas provistas coinciden con la ubicación del sitio descripto o fotografiado, o,',
    marcador22: 'se asigna una respuesta positiva o negativa a la pregunta 1 (se brindan o no coordenadas geográficas), una respuesta negativa a las preguntas 2 y 3 (no se describe en detalle el sitio ni se adjunta una fotografía que permita ubicarlo), pero se responde de manera positiva a las preguntas 5 y 6 (la especie ya fue citada para el país y la localidad está incluida dentro de su rango de distribución conocido o en un sitio que resulta compatible con los sitios de presencia conocida y con su capacidad de dispersión, y las condiciones ecológicas del sitio resultan compatibles con los requerimientos generales de nicho de la especie).',
    paragrafo20: 'La localización se considerará no validada si: ',
    marcador23: 'se responde de manera positiva a la pregunta 1 y a las preguntas 2 y/o 3, pero negativa a la 4 (las coordenadas no coinciden con el sitio descripto o fotografiado), o.  ',
    marcador24: 'se responde de manera negativa a las preguntas 2 y 3, y negativa a al menos una de las preguntas 5 y 6 (el dato no cumple con una o con ninguna de estas dos condiciones: 1- La especie ya está citada para el país y, en ese caso, la localidad está incluida dentro de su rango de distribución conocido o en un sitio que resulta compatible con los sitios de presencia conocida y con su capacidad de dispersión, 2- el sitio reúne condiciones ecológicas compatibles con los requerimientos generales de nicho de la especie).',
    paragrafo21: 'Finalmente, el dato se considera validado en caso de pasar el análisis de validación de los dos criterios (taxonomía y ubicación). '
  },
  comiteCientifico: {
    cabecalhoTitulo: 'Comité científico',
    paragrafo1: 'La Base de Datos Nacional de Especies Exóticas Invasoras cuenta con el apoyo de un grupo de expertos en diversos campos del conocimiento para realizar la validación de los datos recibidos. Este grupo incluye a taxónomos o especialistas en grupos específicos de especies, así como a profesionales con experiencia en el campo cuyos conocimientos son muy relevantes para apoyar la verificación de la información.',
    paragrafo2: 'En el año 2023 se invitó a personas de distintas instituciones y campos de trabajo a conformar un Comité Científico para apoyar la validación de datos y la gestión de la Base de Datos Nacional de Especies Exóticas Invasoras. El papel del Comité Científico es proporcionar apoyo para la validación de datos, aportar datos de interés a la base de datos y ayudar a construir y mantener la red de colaboradores para permitir la actualización de la información y aumentar el número de contribuciones de datos.',
    paragrafo3: 'Las personas que han estado anuentes a formar parte de este primer Comité Científico son las siguientes:',
    marcador1: 'Eduardo Chacón Madrigal, Biólogo, PhD, Centro de Investigación en Biodiversidad y Ecología Tropical, Universidad de Costa Rica. Administrador de la Base de Datos;',
    marcador2: 'Angie Sánchez Núñez, Bióloga, MSc, Vida Silvestre, Sistema Nacional de Areas de Conservación; Ministerio de Ambiente y Energía. Colaboradora y asesora de base de datos;',
    marcador3: 'Shirley Ramírez Carvajal, Bióloga, MSc, Comisión Nacional para la Gestión de la Biodiversidad, Ministerio de Ambiente y Energia. Colaboradora y asesora de base de datos;',
    marcador4: 'Tania Bermúdez Rojas, Bióloga, MSc, Escuela de Ciencias Biológicas, Universidad Nacional. Colaboradora y asesora de base de datos;',
    marcador4b: 'Jimmy Barrantes Madrigal, Biólogo, MSc, Fundacion CR Wildlife / ICOMVIS Universidad Nacional; Colaborador y asesor de base de datos;',
    marcador4c: 'Randall Arguedas Porras, Veterinario, Dr. Servicio Nacional de Salud Animal, Ministerio de Agricultura y Ganadería; Colaborador y asesor de base de datos;',
    marcador4d: 'Brayan Morera Chacón, Biólogo, MSc, Consejo Superior de Investigación Científica, España; Colaborador y asesor de base de datos;',
    marcador4e: 'Arturo Angulo Sibaja, Biólogo, PhD, Centro de Investigación en Biodiversidad y Ecosistemas Terrestres, Universidad de Costa Rica. Colaborador y asesor de base de datos.',
    paragrafo4: 'Atribuciones del Comité Científico',
    marcador5: 'Decidir la inclusión de nuevas especies en la base de datos;',
    marcador6: 'Decidir la eliminación de especies por falta o inconsistencia de los datos;',
    marcador7: 'Apoyo para la validación de la información en la base de datos;',
    marcador8: 'Apoyo para la revisión y búsqueda de datos;',
    marcador9: 'Apoyo para la búsqueda y consulta a colaboradores y expertos para la contribución y validación de datos;',
    marcador10: 'Recomendaciones para mejorar la base de datos;',
    marcador11: 'Mejora continua del nivel de información.'
  },
  especiesInstitucional: {
    titulo: 'Especies',
    paragrafo1: 'Esta es la parte de la base de datos que contiene información acerca de las especies exóticas invasoras. Esos datos se están recopilando desde el año 2004, cuando se realizó el primer relevamiento nacional por parte del Ministerio de Medio Ambiente. La información es enviada por colaboradores diversos, incluyendo especialistas en distintos grupos biológicos que también ayudan a validar datos, y compiladas a partir de artículos científicos y publicaciones técnicas.',
    paragrafo2_1: 'Si tuviera datos de ocurrencias de esas especies o sobre especies exóticas invasoras aún no incluidas, ',
    paragrafo2_2: 'contribuya para actualizar esta base de dados',
    verTutorial: 'Abrir tutorial',
    paragrafo3: 'Por favor utilice el siguiente formato para citar información consultada en este sistema:',
    paragrafo4_1: 'Base de Datos Nacional de Especies Exóticas Invasoras en Costa Rica. Universidad de Costa Rica. Consultado en DD/MM/AAA.',
    paragrafo4_2: ' Acessado em __/__/____ (data)',
    tutorialTitulo1: 'Como citar la base de datos',
    tutorialDescricao1: 'Este es el formato de referencia para la citación de la base de datos en publicaciones.',
    tutorialTitulo2: 'Use los filtros',
    tutorialDescricao2: 'Aquí tenemos algunas opciones para filtrar contenido.',
    tutorialTitulo3: 'Use los filtros: seleccione datos',
    tutorialDescricao3: 'Elija filtros y haga combinaciones para seleccionar datos por lugar, forma biológica, uso, grupo taxonómico, etc.',
    tutorialTitulo4: 'Número de registros',
    tutorialDescricao4: 'Este es el número de especies exóticas invasoras en la base de datos resultante de las búsquedas con filtros.',
    tutorialDescricao5: 'Aquí puede elegir cuántos registros ver por página.',
    tutorialTitulo6: 'Lista de especies exóticas invasoras',
    tutorialDescricao6: 'Lista de todas las especies o resultados del uso de filtros. Haga clic sobre el nombre de la especie para ver los datos completos.',
    tutorialTitulo6_1: 'Exportar datos',
    tutorialDescricao6_1: 'Aquí puede filtrar datos y elegir el formato para exportar.',
    tutorialTitulo7: 'Taxonomía',
    tutorialDescricao7: 'Clasificación taxonómica de la especie seleccionada, nombres comunes, sinónimos, y una foto cuando fuera posible!',
    tutorialTitulo8: 'Ecología y uso',
    tutorialDescricao8: 'Descripción, biología y ecología de la especie seleccionada, de dónde viene, que ambientes invade y usos económicos.',
    tutorialTitulo9: 'Introducción y dispersión',
    tutorialDescricao9: 'Cómo llegó la especie al sitio donde es invasora, por qué fue introducida y cómo se dispersa a otros sitios.',
    tutorialTitulo10: 'Impactos',
    tutorialDescricao10: 'Ambientales, económicos, a la salud y culturales. Clasificación de impactos ambientales por el protocolo EICAT – IUCN.',
    tutorialTitulo11: 'Manejo',
    tutorialDescricao11: 'Medidas preventivas, de detección temprana, control mecánico, químico y biológico para la especie seleccionada.',
    tutorialTitulo12: 'Análisis de Riesgo',
    tutorialDescricao12: 'Resultados de análisis de riesgo para invasión biológica realizadas para la especie seleccionada.',
    tutorialTitulo13: 'Ocurrencias',
    tutorialDescricao13: 'Sitios, ambientes, áreas protegidas, estados donde la especie ocurre en el país y estadio de invasión – vea el mapa!',
    tutorialDescricao14: 'Lista de referencias usadas para compilar datos sobre la especie seleccionada y otras publicaciones sobre la especie.',
    tutorialTitulo15: 'Proyectos',
    tutorialDescricao15: 'Lista de proyectos de investigación y manejo que incluyen la especie seleccionada.'

  },
  colaboradoresInstitucional: {
    titulo: 'Colaboradorxs',
    paragrafo1: 'Lxs colaboradorxs incluyen a quienes aportan datos para este sistema de información así como a quienes colectaron especímenes de museo o ejemplares de herbario, son autores de publicaciones que se utilizaron como fuentes de datos o ayudan en la identificación de especies o la validación de informaciones sobre las especies exóticas invasoras. ',
    paragrafo2: 'La contribución de información sobre las ocurrencias de especies ya listadas es extremadamente relevante para mantener registros de distribución actualizados y detectar nuevos sitios de invasión, contribuyendo con iniciativas de gestión o manejo, así como para la divulgación del conocimiento científico. ',
    paragrafo3: 'Por favor utilice el siguiente formato para citar información consultada en este sistema:',
    paragrafo4_1: 'Base de Datos Nacional de Especies Exóticas Invasoras en Costa Rica. Universidad de Costa Rica. Consultado en DD/MM/AAA.',
    paragrafo4_2: ' Acessado em __/__/____ (data)'
  },
  especiesMarinhasInstitucional: {
    titulo: 'Ecosistemas marinos y costeros',
    paragrafo1: 'El mayor volume del comercio del mundo se realiza a través de embarcaciones que se mueven entre puntos remotos del planeta. Las embarcaciones pueden acarrear organismos pequeños que tienen el potencial de establecerse en los sitios en donde llegan. La mayor parte de las especies invasoras asociadas a los ecosistemas marinos y costeros ha sido introducida de manera accidental, muy probablemente a través del agua de lastre de barcos que la toman en puertos remotos y que desaguan cerca de los puertos destino.',
    paragrafo2: 'De esa forma probablemente han llegado molusos, crustáceos y otros organismos marinos exóticos que se encuentran en nuestras costas. Otras especies exóticas, como el pez león, han llegado a los ecosistemas costeros producto de una liberación accidental de peces importados como especies de acuario. El comercio de especies ornamentales para acuarios importa cientos de especies que tienen el potencial de establecer poblaciónes naturalizadas si no son manejados de manera apropiada.',
    paragrafo3: '',
    paragrafo4: 'Pterois volitans - pez león',
    paragrafo4b: 'Foto: Dinkum',
    paragrafo5: 'Anadara transversa - piangua transvera',
    paragrafo5b: 'Foto: Andrew C',
    paragrafo6: 'Panaeus monodon - camaron tigre gigante',
    paragrafo7: 'Foto: CSIRO Science Image 2992'
  },
  ecosistemasDeAguaDoceInstitucional: {
    titulo: 'Ecosistemas de agua dulce',
    paragrafo1: 'Los ecosistemas de agua dulce son de escencial importancia para la vida humana y la economía. Los ecosistemas de agua dulce incluyen ríos, quebradas, lagunas, lagos y humedales. Estos ecosistemas proveen rutas de transporte, energía eléctrica, irrigación, agua de consumo, recreación, paisajismo y hábitat para la biodiversidad. Una de las mayores amenazas para estos ecosistemas es la invasión por especies exóticas. Plantas acuáticas, peces e invertebrados son los principales grupos que invaden ecosistemas de agua dulce.',
    paragrafo2: '',
    paragrafo3: 'La acuacultura, la pesca depostiva y el comercio de especies ornamentale son las actividades por las que se han introducido especies en estos ecosistemas. Las especies exóticas de agua dulce pueden extinguir especies nativas, afectar la producción de energía eléctrica e incluso el agua para consumo humano. La maleza acuática, Egeria densa, utilizada como planta de acuarios, se ha detectado como problemática en algunos embalses para producción eléctrica en Costa Rica.',
    paragrafo4: '',
    marcador1: '',
    marcador2: '',
    marcador3: '',
    paragrafo5: 'Macrobrachium rosenbergii - camaron azul malayo',
    paragrafo5b: 'Foto: KKPCW',
    paragrafo6: 'Melanoides tuberculata - caracol trompeta malayo',
    paragrafo6b: 'Foto: Dennis L',
    paragrafo7: 'Oreochromis mossambicus - tilapia',
    paragrafo7b: 'Foto: Greg Hume',
    paragrafo8: 'Pontederia crassipes - choreja',
    paragrafo8b: 'Foto: Eduardo Chacón-Madrigal',
    paragrafo9: 'Procambaarus clarkii - camarón de rio americano',
    paragrafo9b: 'Foto: Alvesgaspar'
  },
  ecosistemasTerrestresInstitucional: {
    titulo: 'Ecosistemas terrestres',
    paragrafo1: 'Los humanos habitamos y realizamos múltiples actividades en los ambientes terrestes. Actividades como la agricultura, la ganadería y la urbanización favorecen la llegada de especies exóticas. Algunas de estas especies pueden afectar cultivos y animales de producción, causando pérdidas económicas y por lo tanto se consideren como especies invasoras. Especies introducidas de manera deliverda o accidental pueden invadir ambientes naturales poco alterados, como los bosques, o retardar la restauración natural de estos, teniendo impactos negativos.',
    paragrafo2: 'Por ejemplo, la manzana rosa (Syzygium jambos) es una árbol originalmente introducido por sus frutos comestibles que luego invadió cultivos de café abandonados y bosques aledaños. El pasto jaragua, Hyparrhenia rufa, fue introducido para la ganadería y afecta los bosques tropicales secos porque acelera los ciclos de fuego.',
    paragrafo3: '',
    paragrafo3b: '',
    paragrafo4: 'Hemidactylus frenatus - gecko asiático',
    paragrafo4b: 'Foto: Hopeland',
    paragrafo5: 'Musa velutina - banano rosa',
    paragrafo5b: 'Foto: Eduardo Chacón-Madrigal',
    paragrafo6: 'Nylanderia fulva - hormiga loca',
    paragrafo6b: 'Foto: Bentleypkt',
    paragrafo7: 'Syzygium jambos - manzana rosa',
    paragrafo7b: 'Foto: Eduardo Chacón-Madrigal',
    paragrafo8: 'Ulex europaeus - chucero',
    paragrafo8b: 'Foto: Eduardo Chacon-Madrigal'
  },
  ecosistemasUrbanosInstitucional: {
    titulo: 'Ecosistemas urbanos',
    paragrafo1: 'En los ecosistemas urbanos, los humanos hemos alterado significativamente los abiente naturales. Esta alteración crea oportunidades para que distintas especies puedan colonizar. Sumando a esto, en ciudades y pueblos hay una alta llegada de especies exóticas a través del comercio de mascotas, el transporte de mercancías y la jardinería. Algunas de estas especies se escapan accidentalmente y debido a que hay oportunidades de colonización logran establecerse con mayor probabilidad comparado a otros ecosistemas que están previamente ocupados por otras especies. Además, en sitios ocupados por humanos hay condiciones favorables porque hay eliminación de depredadores naturales y más fuentes de agua y alimento.',
    paragrafo2: 'Algunas especies que se han aprovechado de estos ambientes son las palomas domésticas, las rata común y la noruega, los ratones y distintas plantas. Adicionalmente, hay especies que históricamente han acompañado las migraciones humanas y se establecen en ambientes urbanos como la mosca doméstica y la cucaracha roja, entre otras.',
    paragrafo3: '',
    paragrafo4: 'Columbia livia - paloma europea',
    paragrafo4b: 'Foto: Eduardo Chacon-Madrigal',
    paragrafo5: 'Felis silvestris - Gato doméstico',
    paragrafo5b: 'Foto: Sakthibalan',
    paragrafo6: 'Rattus rattus - rata negra',
    paragrafo6b: 'Foto: CSIRO ScienceImage 10564',
    paragrafo7: ''
  },
  islaDelCocoInstitucional: {
    titulo: 'Isla del Coco',
    paragrafo1: 'La Isla del Coco es una isla oceánica ubicada en el Océano Pacífico a 532 Km de la costa de Costa Rica. Por el aislamiento, ubicación geográfica y tamaño es una sitio de gran interés en la conservación de la biodiversidad marina y terrestre en el mundo ya que cuenta con una alta riqueza de especies y un alto porcentaje de endemismo. A pesar de su importancia en conservación, los ecosistemas de la isla, están amenazados por la introducción de varias especies de animales y plantas. En la Isla del Coco se introdujeron cerdos, venados, cabras, gatos y ratas que actualmente afectan el funcionamiento de un ecosistema que evolucionó sin la presencia de herbívoros o cualquier otro tipo de mamíferos, lo que pone en peligro las polaciones de las especies nativas.',
    paragrafo2: 'Además los humanos han llevado especies de plantas, como el café, que también han cambiado la estructura de la vegetación original y compiten con especies de plantas nativas y endémicas.',
    paragrafo3: 'Coffea arabica - café',
    paragrafo3b: 'Foto: Eduardo Chacón Madrigal',
    paragrafo4: 'Felis silvestris - Gato doméstico',
    paragrafo4b: 'Foto: Sakthibalan',
    paragrafo5: 'Odocoileus virginianus - venado cola branca',
    paragrafo5b: 'Foto: Hannia Rodríguezme',
    paragrafo6: 'Rattus rattus - rata negra',
    paragrafo6b: 'Foto: CSIRO ScienceImage 10564'
  },
  mascotesInstitucional: {
    titulo: 'Mascotas y peces ornamentales',
    paragrafo1: 'El comercio de mascotas y peces ornamentales es una de las vías de introducción de especies exóticas más importantes en cuanto al volumen y diversidad de especies. Las personas compran reptiles, aves y peces a través de sitios o comercios especializados. Aunque la mayoría de organismos se mantiene en cautiverio, algunos son liberados a la naturaleza cuando ya las personas no los quieren o no pueden mantenerlos y otros escapan de manera accidental. Los organismos liberados tiene un chance para de establecerse si encuentran las condiciones adecuadas y pueden competir con especies nativas o transmitirle enfermedades, lo que puede tener un impacto negativo en el ecosistema.',
    paragrafo2: 'No solamente se exportan las especies que se quieren tener como mascotas sino que hay asociado a esta actividad un comercio de otras especies que se utilizan como alimento y otras (ej.plantas acuáticas) que se utilizan para formar el hábitat en las que se mantienen.',
    paragrafo3: '',
    paragrafo4: 'Carassius auratus - carpa',
    paragrafo4b: 'Foto: Felipe Aira',
    paragrafo5: 'Lonchura malacca - munia tricolor',
    paragrafo5b: 'Foto: Shino Jacob Koottanad',
    paragrafo6: 'Poecilia reticulata - Guppy',
    paragrafo6b: 'Foto: Vincent Eisfeld ',
    paragrafo7: 'Xiphophorus variatus - Plati variado',
    paragrafo7b: 'Foto: Marrabbio'
  },
  plantasOrnamentaisInstitucional: {
    titulo: 'Plantas ornamentales',
    paragrafo1: 'Las plantas ornamentales son aquellas que los humanos utilizamos con el propósito de decorar jardines, parques, proyectos de paisajismo, urbanismo o diseño de interiores y floristería. El uso de plantas ornamentales representa una actividad económica creciente y millonaria en todo el mundo. Debido al suministro globalizado y a la demanda de plantas ornamentales, existe una alta movilización de especies entre países. Sin embargo, esta actividad también causa daños ecológicos. Actualmente, el comercio de plantas ornamentales es la principal fuente de plantas exóticas potencialmente invasoras. Más del 50% de todas las especies de plantas invasoras y 85% de las especies leñosas invasoras fueron originalmente introducidas como ornamentales.',
    paragrafo2: 'Los daños ecológicos pueden ser irreversibles y ocasionar grandes pérdidas económicas. La regulación del comercio de plantas ornamentales representa un enorme reto para la legislación y las políticas de sostenibilidad de países de todo el mundo.',
    paragrafo3: '',
    paragrafo4: 'Impatiens hawkeri - china',
    paragrafo4b: 'Foto: Eduardo Chacon Madrigal',
    paragrafo5: 'Spathodea campanulate - Llama del bosque',
    paragrafo5b: 'Foto: Eduardo Chacon Madrigal',
    paragrafo6: 'Thunbergia alata - ojo de poeta',
    paragrafo6b: 'Foto: Eduardo Chacon Madrigal'
  },
  projetosInstitucional: {
    titulo: 'Proyectos',
    paragrafo1: 'En esta sección se incluye información acerca de los proyectos actuales o anteriores enfocados en el estudio y el manejo de las especies exóticas invasoras presentes en Jamaica. Si participa o participó de algún proyecto referido a estas especies, por favor ayude a incorporarlo enviando la información correspondiente a ',
    paragrafo2_1: 'Contribuya con la base de datos, ',
    paragrafo2_2: 'enviando informação sobre o projeto ',
    paragrafo2_3: 'para que podamos registrarlo. Esperamos que la divulgación beneficie su trabajo.',
    paragrafo3: 'Para citar datos del sistema utilice el siguiente formato:',
    paragrafo4_1: 'Base de Datos Nacional de Especies Exóticas Invasoras en Costa Rica. Universidad de Costa Rica. Consultado en DD/MM/AAA.',
    paragrafo4_2: 'Acessado em __/__/____ (data)'
  },
  referenciasInstitucional: {
    titulo: 'Referencias',
    paragrafo1: 'En esta sección se incluye información acerca de los proyectos actuales o anteriores enfocados en el estudio y el manejo de las especies exóticas invasoras presentes en Jamaica. Si participa o participó de algún proyecto referido a estas especies, por favor ayude a incorporarlo enviando la información correspondiente a',
    paragrafo2: 'Si conoce publicaciones sobre especies exóticas invasoras que no están contempladas aquí, contribuya para la actualización de la Base de Datos Nacional a través del envío de esas referencias.',
    paragrafo3: 'Contribuya con datos',
    paragrafo4: 'Por favor utilice el siguiente formato para citar información consultada en este sistema:',
    paragrafo5_1: 'Base de Datos Nacional de Especies Exóticas Invasoras en Costa Rica. Universidad de Costa Rica. Consultado en DD/MM/AAA.',
    paragrafo5_2: 'Consultado en __/__/____ (fecha)'
  },
  usoFlorestalInstitucional: {
    titulo: 'Uso forestal',
    paragrafo1: 'Varias especies de árboles exóticos se introdujeron en Brasil a partir de la década de 1950 por iniciativa del gobierno para desarrollar la industria forestal con el fin de producir papel, pulpa, madera y otros subproductos. Algunas de estas especies se han convertido en invasoras, y los pinos están especialmente distribuidos en numerosos ambientes. Estas especies requieren una gestión que incluya el control continuo y la contención en las masas forestales para evitar el impacto ambiental y paisajístico. La certificación forestal exige el control de las especies exóticas invasoras y es necesario eliminar estos árboles de las áreas naturales, los bordes de las carreteras, las vías férreas, los bosques de ribera y otros ambientes donde no estén cultivados.',
    paragrafo2: 'El género Pinus se considera el género de plantas con mayor área de invasión en el hemisferio sur. El pino desplaza a las especies nativas en los ecosistemas abiertos y es un gran consumidor de agua, más que las especies nativas.',
    paragrafo3: 'Más información en este artículo.',
    paragrafo4: 'Pínus sp. - pino',
    paragrafo4b: 'Foto: Instituto Horus',
    paragrafo5: 'Acacia mearnsii - acacia-negra',
    paragrafo5b: 'Foto: Instituto Horus',
    paragrafo6: 'Acacia mangium - acacia',
    paragrafo6b: 'Foto: Instituto Horus'
  },
  contatoInstitucional: {
    cabecalhoTitulo: 'Contacto',
    contato: 'Contacto',
    p1: 'Complete todos los campos del formulario siguiente:',
    nome: 'Nombre',
    email: 'Correo electrónico',
    instituicao: 'Institución',
    mensagem: 'Mensaje',
    enviar: 'Enviar'
  },
  colaboreDados: {
    cabecalhoTitulo: 'Contribuya con datos',
    paragrafo1: 'Nuestras bases de datos se mantienen actualizadas gracias a los aportes de especialistas y observadores de la naturaleza distribuidos en todo el territorio nacional. Antes de publicarse, la información provista es validada siguiendo criterios acordados a nivel regional.',
    paragrafo2: 'Usted puede aportar datos sobre especies aún no incluidas en este sistema o sobre nuevas localidades de ocurrencia descargando nuestra app (Invasoras AR) o enviando un e-mail a',
    paragrafo2b: 'En ese correo podrá, además puede informarnos acerca de publicaciones o proyectos sobre invasiones biológicas en Costa Rica. Los proveedores de datos que realizan aportes de manera regular reciben un usuario y una contraseña para la carga de información.',
    paragrafo3: 'Ayúdenos a enfrentar el desafío de las especies exóticas invasoras sobre la base de información completa, actualizada y confiable!',
    paragrafo4: 'Muchas gracias!'
  }
}
