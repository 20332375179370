<template>
  <header class="sticky-top">
    <div class="top_menu">
      <div class="container">
        <div class="row">
          <div class="col-6">
            <ul class="list_idioma">
            <li><a :class="{ 'active': $i18n.locale === 'es' }" @click="setLocale('es')">ES</a></li>
            <li><a :class="{ 'active': $i18n.locale === 'en' }" @click="setLocale('en')">EN</a></li>
            <li><a :class="{ 'active': $i18n.locale === 'pt' }" @click="setLocale('pt')">PT</a></li>
            </ul>
          </div>
          <div class="col-6 text-end">
            <ul class="login_top">
              <li v-if="user && user.sysUser"><router-link to="/admin">Admin</router-link></li>
              <li v-else><router-link to="/login">Login</router-link></li>
              <li><router-link to="/contato">{{ $t('label.contato') }}</router-link></li>
            </ul>
          </div>
        </div>
      </div>
    </div>

    <nav class="navbar navbar-expand-lg navbar-light bg-white px-md-3 py-2 institucional-shadow">
      <div class="container">

        <router-link class="navbar-brand" to="/" style="margin-right: 0px">
          <img src="../../assets/institucional/img/logo/logo-ucr.png" alt="" width="103" height="55">
        </router-link>

        <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarCollapse" aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation">
          <span class="navbar-toggler-icon"></span>
        </button>

        <div class="collapse navbar-collapse " id="navbarCollapse">
          <ul class="navbar-nav ms-auto mb-2 mb-md-0 ">
            <!--
              <li class="nav-item">
                <router-link class="institucional nav-link" :class="$route.name === 'Home' ? 'active' : ''" aria-current="page" to="/">Home</router-link>
              </li>
            -->
            <li class="nav-item">
              <router-link class="institucional nav-link" to="/rede-base-de-dados" :class="$route.name === 'RedeBaseDeDados' ? 'active' : ''">{{ $t('label.conhecaNossaRede') }}</router-link>
            </li>
            <li class="nav-item">
              <router-link class="institucional nav-link" to="/base-dados-nacional" :class="$route.name === 'BaseDadosNacional' ? 'active' : ''">{{ $t('label.baseDadosNacional') }}</router-link>
            </li>
            <li class="nav-item">
              <router-link class="institucional nav-link" to="/politica-de-dados" :class="$route.name === 'PoliticaDeDados' ? 'active' : ''">{{ $t('label.politicaDeDados') }}</router-link>
            </li>
            <li class="nav-item">
              <router-link class="institucional nav-link" to="/comite-cientifico" :class="$route.name === 'ComiteCientifico' ? 'active' : ''">{{ $t('label.comiteCientifico') }}</router-link>
            </li>
            <li class="nav-item">
              <router-link class="institucional nav-link" to="/colabore" :class="$route.name === 'ColaboreDados' ? 'active' : ''">{{ $t('label.colabore') }}</router-link>
            </li>
            <li class="nav-item dropdown">
              <a class="institucional nav-link dropdown-toggle"
               href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                {{ $t('label.acesseBaseDados') }}
              </a>
              <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
                <li><router-link class="dropdown-item" to="/especies">{{ $t('label.especies') }}</router-link></li>
                <li><router-link class="dropdown-item" to="/colaboradores">{{ $t('label.colaboradorxs') }}</router-link></li>
                <li><router-link class="dropdown-item" to="/referencias">{{ $t('label.referencias') }}</router-link></li>
                <li><router-link class="dropdown-item" to="/projetos">{{ $t('label.projetos') }}</router-link></li>
              </ul>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  </header>
</template>

<script>
import { mapState } from 'vuex'
export default {
  name: 'InstitucionalHeader',
  computed: mapState(['user']),
  methods: {
    setLocale: function (value) {
      this.$i18n.locale = value
    }
  }
}
</script>
