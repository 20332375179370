<template>
  <!-- Slider temas -->
    <section class="projects-section my-5 py-5 px-4 ">
      <div class="container mt-5">
        <div class="row pb-5 mt-2 mb-4">
          <div class="col-12">
            <h2  class=" mb-0 laranja text-center">{{ $t('home.titulo1') }}</h2>
            <p class="mt-4 text-center">{{ $t('home.conteudo1') }}</p>
          </div>
        </div>
      </div>
      <InstitucionalSliderTemasGlidePt key="glidept" :classtemas="'temapt'" v-if="$i18n.locale === 'pt'"/>
      <InstitucionalSliderTemasGlideEn key="glideen" :classtemas="'temaen'" v-if="$i18n.locale === 'en'"/>
      <InstitucionalSliderTemasGlideEs key="glidees" :classtemas="'temaes'" v-if="$i18n.locale === 'es'"/>
    </section>
    <!-- /Slider temas -->
</template>

<script>
import InstitucionalSliderTemasGlidePt from '@/components/institucional/InstitucionalSliderTemasGlidePt'
import InstitucionalSliderTemasGlideEn from '@/components/institucional/InstitucionalSliderTemasGlideEn'
import InstitucionalSliderTemasGlideEs from '@/components/institucional/InstitucionalSliderTemasGlideEs'
export default {
  name: 'InstitucionalSliderTemas',
  components: { InstitucionalSliderTemasGlidePt, InstitucionalSliderTemasGlideEn, InstitucionalSliderTemasGlideEs }
}
</script>
